import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom'; // Import useHistory for navigation
import PendingComp from 'admin/components/application/PendingComp';
import AdminSidebar from 'admin/sidebar/AdminSidebar';
import { Skeleton } from '@mui/material';
import { PendingCompSkeleton } from '../SkeletonPending';
import { ToastContainer, toast } from 'react-toastify';
import NoDataImage from '../../../assets/images/nodataImg.png'; // Add the path to your image here

function Approved() {
  const navigte = useNavigate(); // Initialize useHistory hook

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      const token = localStorage.getItem('accessTokenAdmin'); // Retrieve token from local storage
      const apiUrl = 'https://backend.washta.com/api/admin/businessBystatus?status=approved'; 
      try {
        const response = await axios.get(apiUrl, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
       
        setData(response.data.data.reverse());
      } catch (err) {
        if (err.response && err.response.status === 401) {
          localStorage.clear(); // Clear local storage
          navigte('/'); // Navigate to the home page
        } else {
          setError(err.message);
        }
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [navigte]);

  const handleTerminate = async (id) => {
    const token = localStorage.getItem('accessTokenAdmin'); // Retrieve token from local storage
    const apiUrl = `https://backend.washta.com/api/admin/businessTerminate/${id}`; // Replace with your API endpoint for PATCH request

    try {
      const response = await axios.patch(apiUrl, {}, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      toast.success('successfully terminate user');
      setData(prevData => prevData.filter(item => item._id !== id));
    } catch (err) {
      console.error('Error approving:', err);
    }
  };
  const gapBtwColmStyle = data?.length === 0 ? {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: '400px',
  } : {};
  return (
    <div className='mainDiv-all-sideBar'>
      <div className='div-sidebar'>
        <AdminSidebar />
      </div>
      <div className='div-Dashbaord'>
        <h1 className='h1-all-heading'>Approved Applications</h1>
        <div className="dashboard">
          <div className='styles-div' style={{ width: '100%'}}>
            <div className='styles-div' style={{ width: '100%' }}>
              <div className='gap-btw-colm' style={gapBtwColmStyle}>
                {loading ? (
                  Array.from(new Array(6)).map((_, index) => (
                    <div key={index} style={{ flex: '1 1 calc(25% - 16px)', marginBottom: '16px' }}>
                      <PendingCompSkeleton />
                    </div>
                  ))
                ) : data?.length === 0 ? (
                  <div >
                    <img src={NoDataImage} alt="No Data" style={{ maxWidth: '100%', maxHeight: '100%' }} />
                  </div>
                ) : (
                  data?.map((item) => (
                    <div key={item._id} style={{ flex: '1 1 calc(25% - 16px)' }}>
                      <PendingComp
                        location={item.business?.location}
                        document={item.business?.uploadDocument}
                        show={false}
                        accept="Accept"
                        reject="Reject"
                        ViewDocument="View Documents"
                        name={item.business?.companyName}
                        date={item.business?.cratedAt}
                        time={item.business?.time}
                        email={item.email}
                        valNumber={item.business?.VATNumber}
                        Ceo={item.business?.position}
                        showBtn={false}
                        TextPopupbtnLast="Terminate Seller"
                        TextpopupbtnHandle={() => handleTerminate(item._id)}
                      />
                    </div>
                  ))
                )}
              </div>
            </div>
            <ToastContainer />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Approved;
