// import React, { useState, useEffect } from 'react';
// import { Line } from 'react-chartjs-2';
// import axios from 'axios';
// import 'chart.js/auto';
// import Graph from '../../../assets/images/Graph.png';
// import { useDispatch, } from 'react-redux';
// import { setStats } from '../../../redux/statsSlice';

// const TotalSalesChart = () => {
//     const [timeframe, setTimeframe] = useState('All time');
//     const [year, setYear] = useState(new Date().getFullYear());
//     const dispatch = useDispatch();
 
//     const [chartData, setChartData] = useState({
//         labels: [],
//         datasets: [
//             {
//                 label: 'Total Orders',
//                 data: [],
//                 fill: true,
//                 backgroundColor: 'rgba(116, 126, 239, 0.2)',
//                 borderColor: '#747EEF',
//                 borderWidth: 0.5,
//             },
//             {
//                 label: 'Total Revenue',
//                 data: [],
//                 fill: true,
//                 backgroundColor: '#7bb8ce96',
//                 borderColor: '#2a328e96',
//                 borderWidth: 0.5,
//             },
//         ],
//     });

//     // const formatLabel = (label) => {
//     //     return timeframe === 'Last Month' ? label : label.slice(0, 3);
//     // };
//     const formatLabel = (label) => {
//         if (timeframe === 'Last Month') {
//             return label; // For Last Month, show full label (e.g., "1 Jan", "2 Jan")
//         } else if (timeframe === 'This Week') {
//             return label.slice(0, 3); // Show only first 3 letters of the day (e.g., Mon, Tue)
//         } else {
//             return label.slice(0, 3); // For other timeframes, show first 3 letters
//         }
//     };

//     // const fetchData = async () => {
//     //     let url = '';
//     //     if (timeframe === 'All time') {
//     //         url = `https://backend.washta.com/api/admin/stats?year=${year}`;
//     //     } else if (timeframe === 'Last Month') {
//     //         url = 'https://backend.washta.com/api/admin/MonthStats';
//     //     } else if (timeframe === 'This Week') {
//     //         url = 'https://backend.washta.com/api/admin/weekstats';
//     //     }

//     //     try {
//     //         const response = await axios.get(url, {
//     //             headers: { Authorization: `Bearer ${localStorage.getItem('accessTokenAdmin')}` },
//     //         });
//     //         const data = response.data.data;
//     //         console.log('API Response Data:', data);

//     //         localStorage.setItem('chartdatavalues', JSON.stringify(data));

//     //         const {
//     //             acceptedOrders,
//     //             averageMonthlySales,
//     //             totalCancelledOrders,
//     //             totalNumberOfOrders,
//     //             totalRevenue,
//     //             averageDailySales,
//     //             totalCompletedOrders,
//     //             totalAcceptedOrders
//     //         } = data;

//     //         dispatch(setStats({
//     //             acceptedOrders,
//     //             averageMonthlySales,
//     //             totalCancelledOrders,
//     //             totalNumberOfOrders,
//     //             totalRevenue,
//     //             averageDailySales,
//     //             totalCompletedOrders,
//     //             totalAcceptedOrders
//     //         }));

//     //         const graphData = data.graphData;
//     //         if (graphData && graphData.length > 0) {
//     //             const labels = graphData.map(item => formatLabel(item.name));
//     //             const totalOrdersData = graphData.map(item => item.totalOrders);
//     //             const totalRevenueData = graphData.map(item => item.totalRevenue);

//     //             setChartData({
//     //                 labels: labels,
//     //                 datasets: [
//     //                     {
//     //                         label: 'Total Orders',
//     //                         data: totalOrdersData,
//     //                         fill: true,
//     //                         backgroundColor: 'rgba(116, 126, 239, 0.2)',
//     //                         borderColor: '#747EEF',
//     //                         borderWidth: 0.5,
//     //                     },
//     //                     {
//     //                         label: 'Total Revenue',
//     //                         data: totalRevenueData,
//     //                         fill: true,
//     //                         backgroundColor: '#7bb8ce96',
//     //                         borderColor: '#2a328e96',
//     //                         borderWidth: 0.5,
//     //                     },
//     //                 ],
//     //             });
//     //         } else {
//     //             console.error('No data available for the selected timeframe');
//     //         }
//     //     } catch (err) {
//     //         console.error('Error fetching data:', err);
//     //     }
//     // };
//     const fetchData = async () => {
//         let url = '';
//         if (timeframe === 'All time') {
//             url = `https://backend.washta.com/api/admin/stats?year=${year}`;
//         } else if (timeframe === 'Last Month') {
//             url = 'https://backend.washta.com/api/admin/MonthStats';
//         } else if (timeframe === 'This Week') {
//             url = 'https://backend.washta.com/api/admin/weekstats';
//         }
    
//         try {
//             const response = await axios.get(url, {
//                 headers: { Authorization: `Bearer ${localStorage.getItem('accessTokenAdmin')}` },
//             });
//             const data = response.data.data;
//             console.log('API Response Data:', data);
    
//             localStorage.setItem('chartdatavalues', JSON.stringify(data));
    
//             const {
//                 acceptedOrders,
//                 averageMonthlySales,
//                 totalCancelledOrders,
//                 totalNumberOfOrders,
//                 totalRevenue,
//                 averageDailySales,
//                 totalCompletedOrders,
//                 totalAcceptedOrders
//             } = data;
    
//             dispatch(setStats({
//                 acceptedOrders,
//                 averageMonthlySales,
//                 totalCancelledOrders,
//                 totalNumberOfOrders,
//                 totalRevenue,
//                 averageDailySales,
//                 totalCompletedOrders,
//                 totalAcceptedOrders
//             }));
    
//             const graphData = data.graphData;
//             if (graphData && graphData.length > 0) {
//                 let labels = [];
//                 let totalOrdersData = [];
//                 let totalRevenueData = [];
    
//                 if (timeframe === 'This Week') {
//                     // Adjust the data to start from Monday
//                     const today = new Date();
//                     const dayOfWeek = today.getDay(); // Sunday = 0, Monday = 1, ..., Saturday = 6
//                     const startOfWeek = new Date(today);
//                     startOfWeek.setDate(today.getDate() - dayOfWeek + (dayOfWeek === 0 ? -6 : 1)); // Adjust to Monday
    
//                     labels = [];
//                     for (let i = 0; i < 7; i++) {
//                         const currentDay = new Date(startOfWeek);
//                         currentDay.setDate(startOfWeek.getDate() + i);
//                         labels.push(formatLabel(currentDay.toLocaleString('default', { weekday: 'long' })));
//                     }
    
//                     // Map the API data to the adjusted labels
//                     totalOrdersData = labels.map((label, index) => {
//                         const dayData = graphData.find(item => item.name.toLowerCase() === label.toLowerCase());
//                         return dayData ? dayData.totalOrders : 0;
//                     });
    
//                     totalRevenueData = labels.map((label, index) => {
//                         const dayData = graphData.find(item => item.name.toLowerCase() === label.toLowerCase());
//                         return dayData ? dayData.totalRevenue : 0;
//                     });
//                 } else {
//                     labels = graphData.map(item => formatLabel(item.name));
//                     totalOrdersData = graphData.map(item => item.totalOrders);
//                     totalRevenueData = graphData.map(item => item.totalRevenue);
//                 }
    
//                 setChartData({
//                     labels: labels,
//                     datasets: [
//                         {
//                             label: 'Total Orders',
//                             data: totalOrdersData,
//                             fill: true,
//                             backgroundColor: 'rgba(116, 126, 239, 0.2)',
//                             borderColor: '#747EEF',
//                             borderWidth: 0.5,
//                         },
//                         {
//                             label: 'Total Revenue',
//                             data: totalRevenueData,
//                             fill: true,
//                             backgroundColor: '#7bb8ce96',
//                             borderColor: '#2a328e96',
//                             borderWidth: 0.5,
//                         },
//                     ],
//                 });
//             } else {
//                 console.error('No data available for the selected timeframe');
//             }
//         } catch (err) {
//             console.error('Error fetching data:', err);
//         }
//     };

//     useEffect(() => {
//         fetchData();
    
//         const handleStorageChange = () => {
//             const data = JSON.parse(localStorage.getItem('chartdatavalues'));
//             if (data) {
//                 const graphData = data.graphData;
//                 if (graphData && graphData.length > 0) {
//                     const labels = graphData.map(item => formatLabel(item.name));
//                     const totalOrdersData = graphData.map(item => item.totalOrders);
//                     const totalRevenueData = graphData.map(item => item.totalRevenue);
    
//                     setChartData({
//                         labels: labels,
//                         datasets: [
//                             {
//                                 label: 'Total Orders',
//                                 data: totalOrdersData,
//                                 fill: true,
//                                 backgroundColor: 'rgba(116, 126, 239, 0.2)',
//                                 borderColor: '#747EEF',
//                                 borderWidth: 0.5,
//                             },
//                             {
//                                 label: 'Total Revenue',
//                                 data: totalRevenueData,
//                                 fill: true,
//                                 backgroundColor: '#7bb8ce96',
//                                 borderColor: '#2a328e96',
//                                 borderWidth: 0.5,
//                             },
//                         ],
//                     });
//                 }
//             }
//         };
    
//         window.addEventListener('storage', handleStorageChange);
    
//         return () => {
//             window.removeEventListener('storage', handleStorageChange);
//         };
//     }, [timeframe, year]);

//     return (
//         <div className="chart-container">
//             <div className='row-main-chart-div'>
//                 <div className='chart-colm-div-heading' style={{ paddingBottom: 0, width: '40%' }}>
//                     <img src={Graph} style={{ width: '8%', objectFit: 'contain' }} alt="Graph Icon" />
//                     <h2 className='main-heading2'>Total Sales</h2>
//                 </div>
//                 <div className='chart-colm-div'>
//                     {timeframe === 'All time' && (
//                         <select style={{ width: '17%' }}
//                             value={year}
//                             onChange={(e) => setYear(e.target.value)}
//                             className="year-select"
//                         >
//                             {Array.from({ length: 10 }, (_, i) => (
//                                 <option key={i} value={new Date().getFullYear() - i}>
//                                     {new Date().getFullYear() - i}
//                                 </option>
//                             ))}
//                         </select>
//                     )}
//                     <div className="buttons">
//                         <button
//                             className={timeframe === 'All time' ? 'active' : 'mainbutton'}
//                             onClick={() => setTimeframe('All time')}
//                         >
//                             All time
//                         </button>
//                         <button
//                             className={timeframe === 'Last Month' ? 'active' : 'mainbutton'}
//                             onClick={() => setTimeframe('Last Month')}
//                         >
//                             Last Month
//                         </button>
//                         <button
//                             className={timeframe === 'This Week' ? 'active' : 'mainbutton'}
//                             onClick={() => setTimeframe('This Week')}
//                         >
//                             This Week
//                         </button>
//                     </div>
//                 </div>
//             </div>

//             <div className='div-chart-div'>
//                 <Line
//                     data={chartData}
//                     options={{
//                         scales: {
//                             y: {
//                                 beginAtZero: true,
//                                 title: {
//                                     display: true,
//                                 },
//                             },
//                         },
//                         plugins: {
//                             legend: { display: true },
//                         },
//                     }}
//                 />
//             </div>
//         </div>
//     );
// };

// export default TotalSalesChart;
import React, { useState, useEffect } from 'react';
import { Line } from 'react-chartjs-2';
import axios from 'axios';
import 'chart.js/auto';
import Graph from '../../../assets/images/Graph.png';
import { useDispatch } from 'react-redux';
import { setStats } from '../../../redux/statsSlice';

const TotalSalesChart = () => {
    const [timeframe, setTimeframe] = useState('All time');
    const [year, setYear] = useState(new Date().getFullYear());
    const dispatch = useDispatch();

    const [chartData, setChartData] = useState({
        labels: [],
        datasets: [
            {
                label: 'Total Orders',
                data: [],
                fill: true,
                backgroundColor: 'rgba(116, 126, 239, 0.2)',
                borderColor: '#747EEF',
                borderWidth: 0.5,
            },
            {
                label: 'Total Revenue',
                data: [],
                fill: true,
                backgroundColor: '#7bb8ce96',
                borderColor: '#2a328e96',
                borderWidth: 0.5,
            },
        ],
    });

    // Updated formatLabel function
    const formatLabel = (label, timeframe) => {
        if (timeframe === 'Last Month') {
            // For "Last Month", format as "Jan 1", "Jan 2", etc.
            const date = new Date(label);
            const month = date.toLocaleString('default', { month: 'short' });
            const day = date.getDate();
            return `${month} ${day}`;
        } else if (timeframe === 'This Week') {
            // For "This Week", show only the first 3 letters of the day (e.g., Mon, Tue)
            return label.slice(0, 3);
        } else {
            // For other timeframes, show the first 3 letters of the label
            return label.slice(0, 3);
        }
    };

    const fetchData = async () => {
        let url = '';
        if (timeframe === 'All time') {
            url = `https://backend.washta.com/api/admin/stats?year=${year}`;
        } else if (timeframe === 'Last Month') {
            url = 'https://backend.washta.com/api/admin/MonthStats';
        } else if (timeframe === 'This Week') {
            url = 'https://backend.washta.com/api/admin/weekstats';
        }

        try {
            const response = await axios.get(url, {
                headers: { Authorization: `Bearer ${localStorage.getItem('accessTokenAdmin')}` },
            });
            const data = response.data.data;
            console.log('API Response Data:', data);

            localStorage.setItem('chartdatavalues', JSON.stringify(data));

            const {
                acceptedOrders,
                averageMonthlySales,
                totalCancelledOrders,
                totalNumberOfOrders,
                totalRevenue,
                averageDailySales,
                totalCompletedOrders,
                totalAcceptedOrders
            } = data;

            dispatch(setStats({
                acceptedOrders,
                averageMonthlySales,
                totalCancelledOrders,
                totalNumberOfOrders,
                totalRevenue,
                averageDailySales,
                totalCompletedOrders,
                totalAcceptedOrders
            }));

            const graphData = data.graphData;
            if (graphData && graphData.length > 0) {
                let labels = [];
                let totalOrdersData = [];
                let totalRevenueData = [];

                if (timeframe === 'This Week') {
                    // Adjust the data to start from Monday
                    const today = new Date();
                    const dayOfWeek = today.getDay(); // Sunday = 0, Monday = 1, ..., Saturday = 6
                    const startOfWeek = new Date(today);
                    startOfWeek.setDate(today.getDate() - dayOfWeek + (dayOfWeek === 0 ? -6 : 1)); // Adjust to Monday

                    labels = [];
                    for (let i = 0; i < 7; i++) {
                        const currentDay = new Date(startOfWeek);
                        currentDay.setDate(startOfWeek.getDate() + i);
                        labels.push(formatLabel(currentDay.toLocaleString('default', { weekday: 'long' }), timeframe));
                    }

                    // Map the API data to the adjusted labels
                    // totalOrdersData = labels.map((label, index) => {
                    //     const dayData = graphData.find(item => item.name.toLowerCase() === label.toLowerCase());
                    //     return dayData ? dayData.totalOrders : 0;
                    // });

                    // totalRevenueData = labels.map((label, index) => {
                    //     const dayData = graphData.find(item => item.name.toLowerCase() === label.toLowerCase());
                    //     return dayData ? dayData.totalRevenue : 0;
                    // });
                    totalOrdersData = labels.map((label) => {
                        const dayData = graphData.find(item => item.name.toLowerCase().startsWith(label.toLowerCase()));
                        return dayData ? dayData.totalOrders : 0;
                    });

totalRevenueData = labels.map((label) => {
    const dayData = graphData.find(item => item.name.toLowerCase().startsWith(label.toLowerCase()));
    return dayData ? dayData.totalRevenue : 0;
});
                } else if (timeframe === 'Last Month') {
                    // Sort the data by date for "Last Month"
                    const sortedData = graphData.sort((a, b) => new Date(a.name) - new Date(b.name));

                    labels = sortedData.map(item => formatLabel(item.name, timeframe));
                    totalOrdersData = sortedData.map(item => item.totalOrders);
                    totalRevenueData = sortedData.map(item => item.totalRevenue);
                } else {
                    labels = graphData.map(item => formatLabel(item.name, timeframe));
                    totalOrdersData = graphData.map(item => item.totalOrders);
                    totalRevenueData = graphData.map(item => item.totalRevenue);
                }

                setChartData({
                    labels: labels,
                    datasets: [
                        {
                            label: 'Total Orders',
                            data: totalOrdersData,
                            fill: true,
                            backgroundColor: 'rgba(116, 126, 239, 0.2)',
                            borderColor: '#747EEF',
                            borderWidth: 0.5,
                        },
                        {
                            label: 'Total Revenue',
                            data: totalRevenueData,
                            fill: true,
                            backgroundColor: '#7bb8ce96',
                            borderColor: '#2a328e96',
                            borderWidth: 0.5,
                        },
                    ],
                });
            } else {
                console.error('No data available for the selected timeframe');
            }
        } catch (err) {
            console.error('Error fetching data:', err);
        }
    };

    useEffect(() => {
        fetchData();

        const handleStorageChange = () => {
            const data = JSON.parse(localStorage.getItem('chartdatavalues'));
            if (data) {
                const graphData = data.graphData;
                if (graphData && graphData.length > 0) {
                    const labels = graphData.map(item => formatLabel(item.name, timeframe));
                    const totalOrdersData = graphData.map(item => item.totalOrders);
                    const totalRevenueData = graphData.map(item => item.totalRevenue);

                    setChartData({
                        labels: labels,
                        datasets: [
                            {
                                label: 'Total Orders',
                                data: totalOrdersData,
                                fill: true,
                                backgroundColor: 'rgba(116, 126, 239, 0.2)',
                                borderColor: '#747EEF',
                                borderWidth: 0.5,
                            },
                            {
                                label: 'Total Revenue',
                                data: totalRevenueData,
                                fill: true,
                                backgroundColor: '#7bb8ce96',
                                borderColor: '#2a328e96',
                                borderWidth: 0.5,
                            },
                        ],
                    });
                }
            }
        };

        window.addEventListener('storage', handleStorageChange);

        return () => {
            window.removeEventListener('storage', handleStorageChange);
        };
    }, [timeframe, year]);

    return (
        <div className="chart-container">
            <div className='row-main-chart-div'>
                <div className='chart-colm-div-heading' style={{ paddingBottom: 0, width: '40%' }}>
                    <img src={Graph} style={{ width: '8%', objectFit: 'contain' }} alt="Graph Icon" />
                    <h2 className='main-heading2'>Total Sales</h2>
                </div>
                <div className='chart-colm-div'>
                    {timeframe === 'All time' && (
                        <select style={{ width: '17%' }}
                            value={year}
                            onChange={(e) => setYear(e.target.value)}
                            className="year-select"
                        >
                            {Array.from({ length: 10 }, (_, i) => (
                                <option key={i} value={new Date().getFullYear() - i}>
                                    {new Date().getFullYear() - i}
                                </option>
                            ))}
                        </select>
                    )}
                    <div className="buttons">
                        <button
                            className={timeframe === 'All time' ? 'active' : 'mainbutton'}
                            onClick={() => setTimeframe('All time')}
                        >
                            All time
                        </button>
                        <button
                            className={timeframe === 'Last Month' ? 'active' : 'mainbutton'}
                            onClick={() => setTimeframe('Last Month')}
                        >
                            Last Month
                        </button>
                        <button
                            className={timeframe === 'This Week' ? 'active' : 'mainbutton'}
                            onClick={() => setTimeframe('This Week')}
                        >
                            This Week
                        </button>
                    </div>
                </div>
            </div>

            <div className='div-chart-div'>
                <Line
                    data={chartData}
                    options={{
                        scales: {
                            y: {
                                beginAtZero: true,
                                title: {
                                    display: true,
                                },
                            },
                        },
                        plugins: {
                            legend: { display: true },
                        },
                    }}
                />
            </div>
        </div>
    );
};

export default TotalSalesChart;