import AdminSidebar from 'admin/sidebar/AdminSidebar'
import ButtonDahbaord from 'components/ButtonDahbaord/ButtonDahbaord'
import React, { useEffect, useState } from 'react'
import { MdDeleteOutline } from "react-icons/md";
import { Navigate, useLocation, useNavigate } from 'react-router-dom';
import ReviewSummary from 'pages/clientDashbaord/dashboard/ratingandReviews/ReviewSummary';
import DonutChart from './DonutChart';
import TotalSalesChart from 'admin/components/dashboardcom/TotalSaleschart';
import { Rating } from '@mui/material';
import './app.css'
import axios from 'axios';
import { formatDate, formatTimeInTimezone } from 'pages/utils/formdatedynamics';
const DetailsCompaniesCom = () => {
    const location = useLocation();
    const navigate = useNavigate()
    const { item } = location.state || {};
    console.log('item',item)
    const [locationText, setLocationText] = useState('');
    const [reviews,setReviews] = useState([])
    const averageRating = 4.5;
      const totalReviews = 273;
      const ratings = {
        1: 150,
        2: 70,
        3: 30,
        4: 15,
        5: 8,
      };
      const recommendationPercentage = 88;
      useEffect(() => {
    window.scrollTo(0, 0);
    const fetchData = async () => {
      const token = localStorage.getItem('accessTokenAdmin'); // Retrieve token from local storage
      const apiUrl = `https://backend.washta.com/api/admin/shopReview?shopId=${item?.shopId?._id}`; // Replace with your API endpoint

      try {
        const response = await axios.get(apiUrl, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        // setData(response.data.data.reverse());
        setReviews(response.data.data)
        // setLoading(false); 
      } catch (err) {
        console.log(err.message);
        // Set loading to false in case of an error
      } 
    };

    fetchData();
  }, []);
  const handleDelete = async (reviewId) => {
    const token = localStorage.getItem('accessTokenAdmin');
    const apiUrl = `https://backend.washta.com/api/admin/deleteReview?reviewId=${reviewId}`
    try {
      const response = await axios.delete(apiUrl, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setReviews((prevReviews) => prevReviews.filter((review) => review._id !== reviewId));
     
    } catch (err) {
      console.error('Error deleting:', err.message);
    }
  };
  const handleDeleteUser = async () => {
    const token = localStorage.getItem('accessTokenAdmin');
    const apiUrl = `https://backend.washta.com/api/admin/shop/${item.shopId?._id}`
    try {
      const response = await axios.delete(apiUrl, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
navigate('/super-admin-dashboard/companies')   
    } catch (err) {
      console.error('Error deleting:', err.message);
    }
  };
  const getLocationFromCoordinates = async (lat, lng) => {
    const apiKey = 'AIzaSyB_nNvYWSCB2haI7DCgR6chQmsg-T4oj8s'; // Replace with your actual API key
    const url = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=${apiKey}`;
  
    try {
      const response = await fetch(url);
      const data = await response.json();
  
      if (data.status === 'OK') {
        const address = data.results[0]?.formatted_address || 'Location not found';
        return address;
      } else {
        throw new Error('Geocoding failed');
      }
    } catch (error) {
      console.error('Error fetching location:', error);
      return 'Location not available';
    }
  };
  useEffect(() => {
    // Only fetch the location if it's empty
    if (!item?.location?.text) {
      const fetchLocation = async () => {
        const address = await getLocationFromCoordinates(item?.location?.coordinates[0], item?.location?.coordinates[1]);
        setLocationText(address);
      };

      fetchLocation();
    } else {
      // If location.text is already available, use it directly
      setLocationText(item?.location?.text);
    }
  }, [item?.location?.coordinates, item?.location?.text]);
  console.log('ocationText',locationText)
 return (
<div className='mainDiv-all-sideBar'>
    <div className='div-sidebar'>
<AdminSidebar/>
    </div>
    <div className='div-Dashbaord'>
        <div style={{display:'flex',justifyContent:'space-between',alignItems:'center',width:'78%'}} >
        <h1 className='h1-all-heading' style={{width:'30%'}}>{item?.shopId?.shopName}</h1>
        <div style={{display:'flex',width:'70%',justifyContent:'end',gap:10}}>
        {/* <ButtonDahbaord background={'purple'} padding={'5px 2px'} handleclick='' textButton='Block Account' height={35} width={'25%'} /> */}
        <ButtonDahbaord background={'purple'} padding={'5px 2px'} handleclick={()=>handleDeleteUser()} textButton='Terminate Seller' height={35} width={'25%'} />
        </div>
        </div>
   
    <div className="dashboard-customer-details">
    <h2>Details</h2>    
      <div className='div-customer-details-main'> 
            
            <div className='popup-inputfeild-application addtion-adding'>
              <p className='vat-number-api'>Location:</p>
              <input className='valt-number-input-feild' placeholder='text' value={item?.location?.text} readOnly />
            </div>
            <div className='popup-inputfeild-application addtion-adding'>
              <p className='vat-number-api'>Shop Active:</p>
              <input className='valt-number-input-feild' placeholder='text' value={item?.shopId?.isActive} readOnly />
            </div>
            
          
      </div>
    </div>

    <div className="dashboard-customer-details">
    <h2>Service</h2>    
      <div className='div-customer-details-main'> 
      <div className='popup-inputfeild-application addtion-adding'>
              <p className='vat-number-api'>Shop Details:</p>
              <input className='valt-number-input-feild' placeholder='text' value={item?.shopId?.shopDetails} readOnly />
            </div>
            <div className='popup-inputfeild-application addtion-adding'>
              <p className='vat-number-api'>Price:</p>
              <input className='valt-number-input-feild' placeholder='text' value={item?.cost + '.00 AED'} readOnly />
            </div>
      </div>
    </div>

    <div className="dashboard-customer-details">
     
    <div style={{width:'100%'}} >
    <h2>Statistics</h2> 
    <div className='destils-row-div'>
   <div className='colm-statis-detsils-company'>
   <DonutChart/>
    </div> 
    <div className='colm-statis-detsils-company'>
    <ReviewSummary boxShadow={'none'} border={'0.5px solid #747eef '} margin={0} color={"#f7f7f7"} text="Customer Ratings"  ratings={ratings}
        averageRating={averageRating}
        totalReviews={totalReviews}
        recommendationPercentage={recommendationPercentage} />
        </div>
        </div> 
        <TotalSalesChart/>
    </div>
    
    </div>

    <div className="dashboard-customer-details">
    <h2>Reviews</h2>    
    {reviews.map((review) => (
        <div key={review.id} className="review-box">
         <div className='div-rewie-main'>
           <h5 className='heading-h5-later'>{review.customerId?.username}</h5>
           <Rating name="half-rating-read" defaultValue={review?.rating} precision={0.5} sx={{fontSize:20,color:'#F39D00'}} readOnly />
         </div>
          <p className='paragph-heading'>{review?.comment?.text}</p>
          
          <div className='div-rewie-main'>
          <p className='paragph-heading addtion-adding'>{formatDate(review?.createdAt)} || {formatTimeInTimezone(review?.createdAt)} </p>
         <div className='replyicons' onClick={()=>handleDelete(review?._id)}>
         <MdDeleteOutline style={{fontSize:23}} />
         <button className='replybtn'>  Delete</button>
         </div>
          </div>
        </div>
      ))}
    </div>

    </div>
    </div>
  )
}

export default DetailsCompaniesCom