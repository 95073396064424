import Sidebar from 'pages/sideBar/SideBar'
import React from 'react'
import { useLocation } from 'react-router-dom';
import { Rating, } from '@mui/material';
import { MdDelete } from "react-icons/md";
import { formatDate, formatTimeInTimezone } from 'pages/utils/formdatedynamics';
import axios from 'axios';
import NodataImg from '../../../../assets/images/noDataImage.jpg'

const AllRatingReviews = () => {
  const location = useLocation()
  const {data} = location.state || {};
  console.log(data)
  const handleDelete = async (reviewId,commetId) => {
    console.log('reasd',reviewId)
    const token = localStorage.getItem('accessToken');
    const apiUrl = `https://backend.washta.com/api/seller/deleteReply/?reviewId=${reviewId}`
     

    try {
      const response = await axios.delete(`https://backend.washta.com/api/seller/deleteReply/?reviewId=${reviewId}&commentId=${commetId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      console.log('Delete successful', response.data);
     
    } catch (err) {
      console.error('Error deleting:', err.message);
    }
  };
  return (
    <div className='mainDiv-all-sideBar'>
    <div className='div-sidebar'>
<Sidebar/>
    </div>
    <div className='div-Dashbaord'>
    <h1 className='h1-all-heading'>All Rating & Reviews </h1>
    <div className="dashboard">
     
      <div className='styles-div'>
      <div className='invoice-border'>
      {data?.length === 0 ? (
  <div className="flex flex-col justify-center items-center bg-white  p-4">
    <img
      src={NodataImg} // Replace with the actual path to your image
      alt="No data available"
     className="w-[28em] object-contain h-[24em] mb-4"
    />
    <p className="text-black-600 text-md">No data available</p>
  </div>
) : (data?.map((review) => (
          <div key={review._id} className="review-box">
          <div className='div-rewie-main'>
            <h5 className='heading-h5-later'>{review.customerId?.username || 'Anonymous'}</h5>
            <Rating name="half-rating-read" value={review.rating || 0} precision={0.5} sx={{ fontSize: 20, color: '#F39D00' }} readOnly />
          </div>
          
                                <p className='paragph-heading addtion-adding'>{review.comment?.text || 'No comment available'}</p>

          <div className='div-rewie-main'>
            <p className='paragph-heading' style={{color:'#747EEF'}}>{formatDate(review.date) + ' At ' + formatTimeInTimezone(review.date)} </p>
           
                  <MdDelete style={{color:'#747EEF',cursor:'pointer',fontSize:23,marginTop:'-10px'}} onClick={() => handleDelete(review._id,review?.comment?._id)} />
               
           
           
          </div>
          
        </div>
      ))
    )}
      </div>
        </div>
        </div>
        </div>

        </div>
  )
}

export default AllRatingReviews