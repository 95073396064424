import Footer from 'components/Footer';
import Header from 'pages/Home1/Header';
import React, { useEffect, useState } from 'react';
import '../Become.css';
import ImgSingin from '../../../assets/images/singup.png';
import Overly from '../../../assets/images/overly.png';
import InputFeild from '../../../components/Input/Input';
import { Button } from '../../../components/Button/index';
import axios from 'axios';
import { useLocation, useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Spinner } from 'react-bootstrap';
import ForgotOtpScreen from './ForgotOtpScreen';

const ForgotPassowrd = () => {
  const navigate = useNavigate();
  const location = useLocation()
  const { item } = location.state || {};
  console.log('itemForgotPassowrd',item)
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const [formData, setFormData] = useState({ 
    email: '',
    role: item,
  });

  const [showOTPComponent, setShowOTPComponent] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleChange = (field, value) => {
    setFormData({
      ...formData,
      [field]: value
    });
  };

  const handleSignup = async () => {
    setLoading(true);
    try {
      const response = await axios.post(
        'https://backend.washta.com/api/otp/sendOTP',
        formData,
        {
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );
  
      // Check if the response indicates success
      if (response.data.status && response.data.code === 200) {
        // Extract success data (accessToken and message)
        // const { accessToken, message } = response.data.data;
  
        // // Store the access token in local storage
        // if (typeof window !== 'undefined') {
        //   localStorage.setItem('accessToken', accessToken);
        // }
  
        // Show success toast with the message
        toast.success(response.data.message || 'OTP sent to your email!');
  
        console.log('Success:', response.data);
  
        // Show the OTP screen
        setShowOTPComponent(true);
      } else {
        // Handle unexpected success responses, if necessary
        toast.error(response.data.message || 'Sign up failed. Please try again.');
        console.error('Error: Invalid response', response.data);
      }
    } catch (error) {
      if (error.response) {
        // Server responded with an error (like Bad Request)
        const { message, error: errorDetail } = error.response.data;
        toast.error(errorDetail || message || 'An error occurred. Please try again.');
        console.error('Error Response:', error.response.data);
      } else {
        // If the error doesn't have a response (e.g., network error)
        toast.error('Network error. Please check your internet connection.');
        console.error('Error:', error);
      }
    } finally {
      setLoading(false);
    }
  };
  
  

  const HandleFunction = () => {
    const colmFirstAdd = document.querySelector('.colm-first.add');
    colmFirstAdd.classList.add('fade-out');
    handleSignup();
  };

  return (
    <>
      <Header />
      <div style={{ position: 'relative' }}>
        <div className='row md:h-auto'>
          <img src={Overly} className='overlyClassName' alt="Logo" />
          <div className='colm-first md:w-[47%] block md:contents !important w-[47%] !important p-[2%]'> <img className='md:mt-[23%]' src={ImgSingin} alt="Logo" /> </div>
          <div className='colm-first add  md:w-[100%] !important w-[47%] !important md:mb-[40px]'      >
            {showOTPComponent ? (
              <ForgotOtpScreen email={formData?.email} item={item} />
            ) : (
              <div className='innerDiv-form md:w-auto'>
                <h1 className='h1-heaing'>Forgot Passowrd  </h1>
                <p className='paragraph-otp'> Enter your email address below, and we'll send Otp on your email to reset your passowrd </p>
                <div className='gapmargin' style={{paddingTop:10,paddingBottom:20}}> <InputFeild paddingBottom='3px' type='text' label="Email" placeholder="Enter your Email" onChange={(value) => handleChange('email', value)} /></div>
                <Button onClick={HandleFunction} shape="round" className="min-w-[100%] sm:px-1" style={{ fontSize: '16px', background: 'white', color: 'black', marginTop: '20px', fontFamily: "'General Sans', sans-serif" }}>
                  {loading ? <Spinner animation="border" style={{color:'#747EEF',width:'1.5em',height:'1.5em'}}  size="sm" /> : 'Forgot Passowrd'}
                </Button>
              </div>
            )}
          </div>
        </div>
      </div>
      <Footer />
      <ToastContainer />
    </>
  );
};

export default ForgotPassowrd;
