
// import React, { useEffect, useState } from 'react'
// import { useNavigate } from 'react-router-dom';
// import AdminSidebar from 'admin/sidebar/AdminSidebar';
// import SaleCom from 'admin/components/saleCom/SaleCom';
// import { PendingCompSkeleton } from 'admin/applications/SkeletonPending';
// import axios from 'axios';
// import DefulatImg from '../../assets/images/app.logo.jpeg'
// import Nodata from '../../assets/images/nosalesdata.png'
// const AdminSales = () => {
//    const navigation = useNavigate()
//    const [data,setData] = useState([])
//    const [loading, setLoading] = useState(true);
   
//     useEffect(() => {
//         window.scrollTo(0, 0);
//       }, []);
//       useEffect(() => {
//         const fetchData = async () => {
//           const token = localStorage.getItem('accessTokenAdmin'); // Retrieve token from local storage
//           const apiUrl = 'https://backend.washta.com/api/admin/SalesShop'; 
//           try {
//             const response = await axios.get(apiUrl, {
//               headers: {
//                 Authorization: `Bearer ${token}`,
//               },
//             });
//             console.log('response.data?.data',response.data?.data)
//             setData(response.data?.data);
//           } catch (err) {
//             if (err.response && err.response.status === 401) {
//               localStorage.clear(); // Clear local storage
//               navigation('/'); // Navigate to the home page
//             } 
//           } finally {
//             setLoading(false);
//           }
//         };
    
//         fetchData();
//       }, []);
//       const navigator = useNavigate()
      
//   return (
//     <div className='mainDiv-all-sideBar'>
//     <div className='div-sidebar'>
// <AdminSidebar/>
//     </div>
//     <div className='div-Dashbaord'>
//     <h1 className='h1-all-heading'>Sales </h1>
//     <div className="dashboard">
//      <div className='styles-div addtionwidth-setting'>
//       <div className="chart-container">      
//       <div  className='gap-btw-colm'>
//         {loading ? (
//                   Array.from(new Array(6)).map((_, index) => (
//                     <div key={index} style={{ flex: '1 1 calc(25% - 16px)', marginBottom: '16px' }}>
//                       <PendingCompSkeleton />
//                     </div>
//                   ))
//                 ) : (
//                   data?.map((item, index) => (
//         <div key={index} style={{ flex: '1 1 calc(25% - 16px)' }}>
//             <SaleCom location={item?.location?.text|| "abc location"} service={item?.service||"Car Washing"} ViewDetails={()=> navigator('/super-admin-dashboard/sales/view-details',{ state: { item: item } })}  ImagePro={item?.coverImage || DefulatImg} name={item?.orders[0]?.shop?.shopName || "No Name Shop"}
//                   />
//         </div>
//         ))
//         )}
                
//         </div>
//       </div>
//       </div>
//     </div>
//     </div>
//     </div>
//   )
// }

// export default AdminSales
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import AdminSidebar from 'admin/sidebar/AdminSidebar';
import SaleCom from 'admin/components/saleCom/SaleCom';
import { PendingCompSkeleton } from 'admin/applications/SkeletonPending';
import axios from 'axios';
import DefulatImg from '../../assets/images/app.logo.jpeg';
import Nodata from '../../assets/images/nosalesdata.png';

const AdminSales = () => {
   const navigate = useNavigate();
   const [data, setData] = useState([]);
   const [loading, setLoading] = useState(true);
   
   useEffect(() => {
      window.scrollTo(0, 0);
   }, []);

   useEffect(() => {
      const fetchData = async () => {
         const token = localStorage.getItem('accessTokenAdmin');
         const apiUrl = 'https://backend.washta.com/api/admin/SalesShop'; 

         try {
            const response = await axios.get(apiUrl, {
               headers: { Authorization: `Bearer ${token}` },
            });
            console.log('response.data?.data', response.data?.data);
            setData(response.data?.data || []);
         } catch (err) {
            if (err.response && err.response.status === 401) {
               localStorage.clear();
               navigate('/');
            }
         } finally {
            setLoading(false);
         }
      };

      fetchData();
   }, [navigate]);

   return (
      <div className='mainDiv-all-sideBar'>
         <div className='div-sidebar'>
            <AdminSidebar />
         </div>
         <div className='div-Dashbaord'>
            <h1 className='h1-all-heading'>Sales</h1>
            <div className="dashboard">
               <div className='styles-div addtionwidth-setting'>
                  <div className="chart-container">      
                     <div     className='gap-btw-colm' 
                        style={{
                           display: "grid",
                           gridTemplateColumns: data?.length === 0 
                              ? "auto" 
                              : "repeat(auto-fill, minmax(250px, 1fr))",
                           gap: "16px",
                        }}>
                        {loading ? (
                           Array.from(new Array(6)).map((_, index) => (
                              <div key={index} style={{ flex: '1 1 calc(25% - 16px)', marginBottom: '16px' }}>
                                 <PendingCompSkeleton />
                              </div>
                           ))
                        ) : data?.length === 0 ? (
                           // ✅ Show No Sales Data Image when data is empty
                           <div style={{ textAlign: 'center', width: '100%' }}>
                              <img src={Nodata} alt="No Sales Data" style={{ width: '300px', margin: '20px auto' }} />
                              <p  className="h1-all-heading" style={{ fontSize: '18px',paddingBottom:20 }}>No Sales Data Available</p>
                           </div>
                        ) : (
                           data?.map((item, index) => (
                              <div key={index} style={{ flex: '1 1 calc(25% - 16px)' }}>
                                 <SaleCom 
                                    location={item?.location?.text || "abc location"} 
                                    service={item?.service || "Car Washing"} 
                                    ViewDetails={() => navigate('/super-admin-dashboard/sales/view-details', { state: { item } })}  
                                    ImagePro={item?.coverImage || DefulatImg} 
                                    name={item?.orders[0]?.shop?.shopName || "No Name Shop"}
                                 />
                              </div>
                           ))
                        )}
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </div>
   );
}

export default AdminSales;
