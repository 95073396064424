import Footer from 'components/Footer';
import Header from 'pages/Home1/Header';
import React, { useEffect, useState } from 'react';
import './Become.css';
import ImgSingin from '../../assets/images/singup.png';
import Overly from '../../assets/images/overly.png';
import InputFeild from 'components/Input/Input';
import { Button } from 'components';
import OTPComponent from './Otp';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Spinner } from 'react-bootstrap';
import { Link } from 'react-router-dom';

const About = () => {
  const navigate = useNavigate();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const [formData, setFormData] = useState({
    username: '',
    password: '',
    email: '',
    fullname: '',
    phone: '',
    role: 'seller',
  });

  const [showOTPComponent, setShowOTPComponent] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleChange = (field, value) => {
    setFormData({
      ...formData,
      [field]: value
    });
  };

  const handleSignup = async () => {
    setLoading(true);
    try {
      const response = await axios.post('https://backend.washta.com/api/auth/Signup', formData, {
        headers: {
          'Content-Type': 'application/json'
        }
      });
    
    if (response.data.status && response.data.code === 200) {
      const accessToken = response.data.data.accessToken;
      if (typeof window !== 'undefined') {
          localStorage.setItem('accessToken', accessToken);
      }
      toast.success('Sign in successful!');
      console.log('Success:', response.data);
      setShowOTPComponent(true);
//   } else {
//       toast.error('Sign in failed. Please try again.');
//       console.error('Error: Invalid response', response.data);
//   }
// }catch (error) {
//       toast.error('Sign up failed. Please try again.', {
//       });
//       console.error('Error:', error);
//     } finally {
//       setLoading(false);
//     }
//   };
    }else {
  toast.error('Sign in failed. Please try again.');
}
} catch (error) {
// Check if error.response exists and has a message
const errorMessage = error.response?.data?.error || 'Sign in failed. Please try again.';
toast.error(errorMessage);
console.error('Error:', error.response);
} finally {
setLoading(false);
}
};

  const HandleFunction = () => {
    const colmFirstAdd = document.querySelector('.colm-first.add');
    colmFirstAdd.classList.add('fade-out');
    handleSignup();
  };

  return (
    <>
      <Header />
      <div style={{ position: 'relative' }}>
        <div className='row md:h-auto'>
          <img src={Overly} className='overlyClassName' alt="Logo" />
          <div className='colm-first md:w-[47%] block md:contents !important w-[47%] !important p-[2%]'> <img className='md:mt-[23%]' src={ImgSingin} alt="Logo" /> </div>
          <div className='colm-first add  md:w-[100%] !important w-[47%] !important md:mb-[40px]'      >
            {showOTPComponent ? (
              <OTPComponent email={formData?.email} />
            ) : (
              <div className='innerDiv-form md:w-auto'>
                <h1 className='h1-heaing'> We’re glad to have you! </h1>
                <InputFeild paddingBottom='3px' type='text' label="Username" placeholder="Enter your Username" onChange={(value) => handleChange('username', value)} />
                <div className='gapmargin'><InputFeild paddingBottom='3px' type='text' label="Name" placeholder="Enter your name" onChange={(value) => handleChange('fullname', value)} /></div>
                <div className='gapmargin'> <InputFeild paddingBottom='3px' type='text' label="Email" placeholder="Enter your Email" onChange={(value) => handleChange('email', value)} /></div>
                <div className='gapmargin'> <InputFeild type="number"  paddingBottom='3px'  label="Phone Number" placeholder="Enter your Phone Number" onChange={(value) => handleChange('phone', value)} /></div>
                <div className='gapmargin'> <InputFeild paddingBottom='3px' type='password' label="Password" placeholder="Enter your Password" onChange={(value) => handleChange('password', value)} /></div>
                <Button onClick={HandleFunction} shape="round" className="min-w-[100%] sm:px-1" style={{ fontSize: '16px', background: 'white', color: 'black', marginTop: '20px', fontFamily: "'General Sans', sans-serif" }}>
                  {loading ? <Spinner animation="border" style={{color:'#747EEF',width:'1.5em',height:'1.5em'}}  size="sm" /> : 'Sign up'}
                </Button>
                <p className="text-center text-sm text-white mt-4 flex gap-2 font-[Manrope]">
        Already have an account?{' '}
        <Link to="/sign-in" className="text-[white] font-medium hover:underline font-[Manrope]">
          Sign in
        </Link>
      </p>
              </div>
            )}
            
          </div>
         
        </div>
        
      </div>
      <Footer />
      <ToastContainer />
    </>
  );
};

export default About;
