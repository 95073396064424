import React from 'react'
import Imgage from '../../assets/images/img.png'
import HomeColumnBetter from "../../components/HomeColumnBetter";
import './style.css'
import { Img } from "../../components";
import ADS from './Washta Icons-01.svg'
import CardImg from '../../assets/images/Paymnet.png'
import Squareimg from '../../assets/images/Square.svg'
import ASDf from './Washta-Icons.svg'
const card = () => {
  return (
    <div className='btn-card'>
    <div className="flex gap-[35px] self-stretch md:flex-col w-[91%] m-auto">
    <div className="relative h-[350px] w-full rounded-[26px] border border-solid border-black-900_26 bg-indigo-300 bg-[url(/public/images/img_frame_1171276568.png)] bg-cover bg-no-repeat px-[41px] md:px-5 md:pt-5">
        <div className="absolute  left-0 right-0 m-auto h-[709px]  md:h-auto" style={{top:0}}>
          <img src={Imgage} alt="main"  style={{borderRadius:'27px'}} />
         
        </div>
      </div>
      <div className="flex w-full flex-col gap-[35px]">
        {[...Array(1)].map((d, index) => (
          <HomeColumnBetter
            image={ADS}
            text="Select Your Location and Request Your Carwash                      "
            text1="Our trusted car wash providers are already stationed at popular malls and parking lots, no waiting for them to arrive! Simply open the app, see the available carwash service, and request a cleaning. It’s that easy."
            key={"listbetter" + index}
            className="flex flex-1 flex-col items-start justify-center rounded-[26px] border border-solid border-black-900_7f p-[25px] sm:p-5"
          />
        ))}
         <HomeColumnBetter
            image={CardImg}
            text="Pay Online, Hassle-Free"
            text1="No need to carry cash. With our secure in-app payment system, you can use your credit/debit and pay in seconds. Fast, secure, and convenient. "
            key={"listbetter"}
            className="flex flex-1 flex-col items-start justify-center rounded-[26px] border border-solid border-black-900_7f p-[25px] sm:p-5"
          />
      </div>
     
      <div className="flex w-full flex-col gap-[35px]">
        {[...Array(1)].map((d, index) => (
          <HomeColumnBetter
            image={ASDf}
            text="Enjoy a Spotless Car When You Return"
            text1="By the time you’re done, your car will be spotless, shining, and ready to go. No waiting in lines, no extra trips, and no hassle. Just enjoy your cleaned car, right where you left it."
            key={"listautomated" + index}
            className="flex flex-1 flex-col items-start justify-center rounded-[26px] border border-solid border-black-900_7f p-[25px] sm:p-5"
          />
        ))}
         <HomeColumnBetter
            image={Squareimg}
            text="Run Your Errands, We’ll Take Care of the Rest"
            text1="While you’re busy shopping, enjoying a meal, or attending meetings, our professional car wash teams will get to work. There’s no need to coordinate schedules or meet anyone; we’ll handle everything while you go about your day."
            key={"listbetter"}
            className="flex flex-1 flex-col items-start justify-center rounded-[26px] border border-solid border-black-900_7f p-[25px] sm:p-5"
          />
      </div>
    </div>
    </div>
  )
}

export default card