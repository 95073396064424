import React, { useState, useEffect } from 'react';
import './Order.css';
import Locationicon from '../../assets/images/location.png';
import ButtonDahbaord from 'components/ButtonDahbaord/ButtonDahbaord';
const ActiveOrders = ({ Accpet, Complete, orderlocation, timer, status, billingStatus, Price, Refuse, promoCode }) => {
  const [showButtons, setShowButtons] = useState(true);
  const [isAccepted, setIsAccepted] = useState(false);
  const [finalPrice, setFinalPrice] = useState(parseFloat(Price));
  const [discountAmount, setDiscountAmount] = useState(0);

  useEffect(() => {
    if (status === 'inprocess') {
      setIsAccepted(true);
      setShowButtons(true);
    } else if (status === 'completed') {
      setShowButtons(false);
    } else {
      setShowButtons(true);
    }

    // Apply promo code discount if available
    if (promoCode) {
      let discount = 0;
      if (promoCode.Discounttype === 'percentage') {
        discount = (parseFloat(Price) * parseFloat(promoCode.discount)) / 100;
      } else if (promoCode.Discounttype === 'fixed') {
        discount = parseFloat(promoCode.discount);
      }
      setDiscountAmount(discount);
      setFinalPrice(parseFloat(Price) - discount);
    }
  }, [status, Price, promoCode]);

  const handleRefuse = () => {
    Refuse();
    setShowButtons(false);
  };

  const handleAccept = () => {
    Accpet();
    setIsAccepted(true);
  };

  const handleComplete = () => {
    Complete();
    setShowButtons(false);
  };

  return (
    <div className='order-componet'>
      <div className='row-invoice' style={{ justifyContent: 'flex-start', alignItems: "flex-start" }}>
      {promoCode && promoCode.promoCode && (
        <div className='bg-[#747EEF] absolute right-[0px] top-[31%] p-[5px] rounded-l-[6px]' >
         <h2 className='text-white text-[10px]'>  Discount = {promoCode.discount}{promoCode.Discounttype === "percentage" ? "%" : "AED"}</h2>

          </div> )}        <div className='div-order-active' style={{width:'100%',alignItems:'flex-start'}}>
          <img src={Locationicon} style={{ width: '1.3em', objectFit: 'contain' }} />
          <h5 className='h5-heaidng-order'> {orderlocation} <br></br> {promoCode && promoCode.promoCode && (
            <p className='paragph-heading'>Promo Code Applied: <span style={{color:'rgb(116 126 239)',fontWeight:800}} className='text-[rgb(116 126 239)]'>{promoCode.promoCode}</span> </p>
          )}</h5>
          
        </div>
        <div className='timer'>
          <p className='paragph-heading'>{timer}</p>
          {/* {discountAmount > 0 && (
          <div className='div-colm-order'>
            <p className='paragph-heading-text'>-{discountAmount.toFixed(2)} AED</p>
          </div>
        )} */}
        </div>
      </div>
      <div className='row-invoice adding-left-padding'>
        <div className='div-colm-order'>
          <p className='paragph-heading'>Status</p>
          <p className='paragph-heading-text'>{status}</p>
        </div>
        <div className='div-colm-order'>
          <p className='paragph-heading'>Billing Status</p>
          <p className='paragph-heading-text'>{billingStatus}</p>
        </div>
        <div className='div-colm-order'>
          <p className='paragph-heading'>Original Price</p>
          <p className='paragph-heading-text'><s>{parseFloat(Price).toFixed(2)} AED</s></p>
        </div>
        
        <div className='div-colm-order'>
          <p className='paragph-heading'>Final Price</p>
          <p className='paragph-heading-text'>{finalPrice.toFixed(2)} AED</p>
        </div>
      </div>
      {showButtons ? (
        <div className='btn-both adding-margin'>
          {status === 'ongoing' && (
            <>
              <ButtonDahbaord handleclick={handleRefuse} textButton="Refuse" width={'100%'} height={35} />
              <ButtonDahbaord handleclick={handleAccept} textButton='Accept' background={'purple'} width={'100%'} height={35} />
            </>
          )}
          {(status === 'inprocess' || isAccepted) && (
            <ButtonDahbaord handleclick={handleComplete} textButton='Completed' background={'purple'} width={'100%'} height={35} />
          )}
        </div>
      ) : status === 'inprocess' ? (
        <ButtonDahbaord handleclick={handleComplete} textButton='Completed' background={'purple'} width={'100%'} height={35} />
      ) : null}
    </div>
  );
};

export default ActiveOrders;
