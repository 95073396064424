
import AdminSidebar from 'admin/sidebar/AdminSidebar';
import React, { useEffect } from 'react'
import AllCustomerCom from '../AllCustomerCom';
import { useLocation, useNavigate } from 'react-router-dom';
const ViewAllCompanies = () => {
    const location = useLocation();
    const { item } = location.state || {};
    console.log('itemitemasdasdasd',item)
    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);
      const navigator  = useNavigate()
     
  return (
    <div className='mainDiv-all-sideBar'>
    <div className='div-sidebar'>
<AdminSidebar/>
    </div>
    <div className='div-Dashbaord'>
    <h1 className='h1-all-heading'>All Companies </h1>
    <div className="dashboard">    
      <div className='styles-div addtionwidth-setting'>
        {item?.filter(item => item.billingStatus !== "non-paid")?.map((item)=>(
            <div key={item.id}>
                <AllCustomerCom
                Imaag={item.shopId?.coverImage  }
                // item={item}
                onclick={()=> navigator('/super-admin-dashboard/companies/all-companies/companies-details',{ state: { item: item } })}
                 status={item.status} 
                 name={item.shopId?.shopName}
                payment={item.cost}
                date={item.date|| item?.createdAt}/>
            </div>

        ))}
   
      
      </div>
    </div>
    </div>
    </div>
  )
}

export default ViewAllCompanies