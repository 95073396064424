import React from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, IconButton, Typography } from '@mui/material';
import { IoCloseOutline } from "react-icons/io5";
import Barcode from 'react-barcode';
import ButtonDahbaord from 'components/ButtonDahbaord/ButtonDahbaord';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import './Recepit.css';
import { formatDate, formatTimeInTimezone } from 'pages/utils/formdatedynamics';

const InvoicePopup = ({ show, onClose, invoiceData, showFields }) => {
  if (!show) return null;
console.log('invoiceData',invoiceData)
  const cost = parseFloat(invoiceData?.cost || 0);
  const washtaFeePercentage = parseFloat(invoiceData?.serviceFee?.[0]?.WashtaFees || 0);
  const vatPercentage = 5; // Fixed at 5%
  const washtaFee = (cost * washtaFeePercentage) / 100;
  const vatFee = (cost * vatPercentage) / 100;
  const total = cost - washtaFee - vatFee - invoiceData?.discount;
console.log('total',total)
  const barcodeData = `Order ID: ${invoiceData.orderId}`;

  const handleDownloadReceipt = async () => {
    const element = document.getElementById('invoice-content');
    const canvas = await html2canvas(element, { scale: 2 });
    const imgData = canvas.toDataURL('image/png');
    const pdf = new jsPDF('p', 'mm', 'a4');
    const pdfWidth = 190; // PDF width
    const pdfHeight = (canvas.height * pdfWidth) / canvas.width; // Maintain aspect ratio
    let yPosition = 10; // Starting position for content

    pdf.addImage(imgData, 'PNG', 10, yPosition, pdfWidth, pdfHeight);
    pdf.save('invoice.pdf');
  };

  return (
    <Dialog
      open={show}
      onClose={onClose}
      fullWidth
      maxWidth="sm"
      PaperProps={{
        sx: {
          padding: 2,
          borderRadius: 2,
        },
      }}
    >
      <DialogTitle>
        Invoice Details
        <IconButton
          edge="end"
          color="inherit"
          onClick={onClose}
          aria-label="close"
          sx={{ position: 'absolute', right: 8, top: 8, color: (theme) => theme.palette.grey[500] }}
        >
          <IoCloseOutline />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <div id="invoice-content" className="invoice-popup-content">
          <Barcode displayValue={false} value={barcodeData} className="barcode" />
          <div className="receipt-details">
            {[
              { label: 'Shop Name', value: invoiceData?.shopId?.shopName },
              { label: 'Shop Location', value: invoiceData?.shopId?.location?.text },
              { label: 'Customer Location', value: invoiceData?.location?.text || "no user location" },
              { label: 'Customer Email', value: `${invoiceData?.customerId?.email}` },
              { label: 'Customer Phone Number', value: `${invoiceData?.customerId?.phone}` },
              { label: 'Booking Date', value: formatDate(invoiceData?.date) + " At " + formatTimeInTimezone(invoiceData?.date) },
              { label: 'Car Plate Number', value: invoiceData?.vehicleId?.vehiclePlateNumber },
              { label: 'Car Type', value: invoiceData?.vehicleId?.vehicleType },
              { label: 'Shop Fee', value: `${cost.toFixed(2)} AED` },
              // { label: `Promo Code Discount (${invoiceData?.promoCode?.discount} ${invoiceData?.promoCode?.Discounttype === 'percentage' ? '%' : ' AED'})`, value: `${invoiceData?.discount} .00 AED` },
              { 
                label: invoiceData?.promoCode ? `Promo Code Discount (${invoiceData?.promoCode?.discount} ${invoiceData?.promoCode?.Discounttype === 'percentage' ? '%' : ' AED'})` : 'Promo Code Discount',
                value: invoiceData?.promoCode ? `${invoiceData?.discount} .00 AED` : '0.00 AED'
              },
              { label: 'VAT 5%', value: `${vatFee.toFixed(2)} AED` },
                    { label: `Washta Fee (${washtaFeePercentage}%)`, value: `${washtaFee.toFixed(2)} AED` },
                    { label: 'Total', value: `${total.toFixed(2)} AED` },
              // ...(showFields
              //   ? [
              //       { label: 'VAT 5%', value: `${vatFee.toFixed(2)} AED` },
              //       { label: `Washta Fee (${washtaFeePercentage}%)`, value: `${washtaFee.toFixed(2)} AED` },
              //       { label: 'Total', value: `${total.toFixed(2)} AED` },
              //     ]
              //   : []),
              { label: 'Payment ID', value: invoiceData?.paymentId },
            ].map((item, index) => (
              <div key={index} className="receipt-row">
                <span variant="body2" className="receipt-label" style={{ width: '33%' }}>{item.label}</span>
                <Typography variant="body2" className="receipt-value">{item.value}</Typography>
              </div>
            ))}
          </div>
        </div>
      </DialogContent>

      <DialogActions>
        <div className="paymanet-div-super-admin">
          <Typography variant="body2">Payment</Typography>
          <Typography variant="body1">
            {total}.00 <span style={{ fontSize: 12 }}>AED</span>
          </Typography>
        </div>
        <div className="second-payment-div">
          <ButtonDahbaord
            textButton="Download E-Receipt"
            height={32}
            width="50%"
            background="purple"
            handleclick={handleDownloadReceipt}
          />
        </div>
      </DialogActions>
    </Dialog>
  );
};

export default InvoicePopup;
