
// import React, { useState, useEffect } from 'react';
// import Switch from 'react-switch';
// import './SellershopCom.css';
// import { CiClock2, CiLocationOn } from "react-icons/ci";
// import ButtonDahbaord from 'components/ButtonDahbaord/ButtonDahbaord';

// const SellerShopCom = ({ Heading, type, timing, location, Price, img, onlcick, isActive, onToggleChange, editOnclick }) => {
//     const [checked, setChecked] = useState(isActive);

//     useEffect(() => {
//         setChecked(isActive);
//     }, [isActive]);

//     const handleChange = (nextChecked) => {
//         setChecked(nextChecked);
//         onToggleChange(); // Trigger the toggle change function from the parent component
//     };

//     const formatTimings = (timings, type) => {
//         if (!timings || typeof timings !== 'object') {
//             return "Timing information not available";
//         }
    
//         const daysOrder = ["monday", "tuesday", "wednesday", "thursday", "friday", "saturday", "sunday"];
//         const formattedDays = daysOrder.map((day) => {
//             const details = timings[day];
//             if (!details || !details.open) {
//                 return { day, status: "Closed" };
//             }
//             const from = new Date(details.from).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: true });
//             const to = new Date(details.to).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: true });
//             return { day, status: `${from} - ${to}` };
//         });
    
//         if (type === 'open') {
//             // Group days with the same timings for open days
//             const grouped = formattedDays.reduce((acc, { day, status }) => {
//                 if (status === "Closed") return acc; // Skip closed days
//                 const found = acc.find(group => group.status === status);
//                 if (found) {
//                     found.days.push(day.charAt(0).toUpperCase() + day.slice(1, 3)); // Add abbreviated day
//                 } else {
//                     acc.push({ status, days: [day.charAt(0).toUpperCase() + day.slice(1, 3)] });
//                 }
//                 return acc;
//             }, []);
    
//             return grouped
//                 .map(group => `${group.days.join(", ")}: ${group.status}`)
//                 .join(", ") || "No open timings available";
//         }
    
//         if (type === 'closed') {
//             // Group days with the "Closed" status
//             const closedDays = formattedDays
//                 .filter(({ status }) => status === "Closed")
//                 .map(({ day }) => day.charAt(0).toUpperCase() + day.slice(1, 3))
//                 .join(", ");
            
//             return closedDays || "No closed timings";
//         }
    
//         return "Invalid type";
//     };
    
    
    
    
    

//     return (
//         <div className='div-sellar-shop'>
//             <img className='seller-image-shop' src={img || 'https://img.freepik.com/free-photo/professional-washer-blue-uniform-washing-luxury-car-with-water-gun-open-air-car-wash_496169-333.jpg'} alt="Shop" />
//             <div className='text-heading-toggle'>
//                 <h5 className='seller-shop' style={{ paddingTop: '0em' }}> {Heading} </h5>
//                 <Switch
//                     checked={checked}
//                     onChange={handleChange}
//                     offColor="#bcbcbc"
//                     onColor="#747EEF"
//                     offHandleColor="#ffffff"
//                     onHandleColor="#ffffff"
//                     handleDiameter={18}
//                     uncheckedIcon={false}
//                     checkedIcon={false}
//                     boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
//                     activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
//                     height={22}
//                     width={48}
//                 />
//             </div>
//             <p className='paragraph-text-shop' style={{display:'flex',alignItems:'center'}}>
//             <CiClock2 />
//             <p style={{fontSize:13,fontWeight:'400',fontFamily:'"Manrope", sans-serif'}}>
//         Timing: {formatTimings(timing, 'open')}
//     </p>
//     </p>

// <div className='icon-div-row'>
//     <CiClock2 />
//     <p className='icon-patagraph'>
//         Closed: {formatTimings(timing, 'closed')}
//     </p>
// </div>
//             <div className='icon-div-row'>
//                 <CiLocationOn />
//                 <p className='icon-patagraph'>
//                     Location: <span className='location-text'>{location}</span>
//                 </p>
//             </div>
//             <h5 className='seller-shop'>AED {Price}</h5>
//             <div className='btn-both adding-margin'>
//                 <ButtonDahbaord handleclick={onlcick} textButton="Delete" width={'100%'} height={38} />
//                 <ButtonDahbaord handleclick={editOnclick} textButton='Edit' background={'purple'} width={'100%'} height={38} />
//             </div>
//         </div>
//     );
// };

// export default SellerShopCom;

import React, { useState, useEffect } from 'react';
import Switch from 'react-switch';
import './SellershopCom.css';
import { CiClock2, CiLocationOn } from "react-icons/ci";
import ButtonDahbaord from 'components/ButtonDahbaord/ButtonDahbaord';

const SellerShopCom = ({ Heading, type, timing, location, Price, img, onlcick, isActive, onToggleChange, editOnclick }) => {
    const [checked, setChecked] = useState(isActive);
    useEffect(() => {
        setChecked(isActive);
    }, [isActive]);

    const handleChange = (nextChecked) => {
        setChecked(nextChecked);
        onToggleChange(); // Trigger the toggle change function from the parent component
    };
    const formatTimings = (timings, type) => {
        if (!timings || typeof timings !== 'object') {
            return "Timing information not available";
        }
    
        const daysOrder = ["monday", "tuesday", "wednesday", "thursday", "friday", "saturday", "sunday"];
        const formattedDays = daysOrder.map((day) => {
            const details = timings[day];
            if (!details || !details.open) {
                return { day, status: "Closed" };
            }
    
            const formatTime = (time) => {
                if (!time) return '';
                const [hour, minute] = time.split(':').map(Number);
                const period = hour >= 12 ? 'PM' : 'AM';
                const formattedHour = hour % 12 || 12; // Convert 24-hour to 12-hour format
                return `${formattedHour}:${minute.toString().padStart(2, '0')} ${period}`;
            };
    
            const from = formatTime(details.from);
            const to = formatTime(details.to);
            return { day, status: `${from} - ${to}` };
        });
    
        if (type === 'open') {
            // Group days with the same timings for open days
            const grouped = formattedDays.reduce((acc, { day, status }) => {
                if (status === "Closed") return acc; // Skip closed days
                const found = acc.find(group => group.status === status);
                if (found) {
                    found.days.push(day.charAt(0).toUpperCase() + day.slice(1, 3)); // Add abbreviated day
                } else {
                    acc.push({ status, days: [day.charAt(0).toUpperCase() + day.slice(1, 3)] });
                }
                return acc;
            }, []);
    
            return grouped
                .map(group => `${group.days.join(", ")}: ${group.status}`)
                .join(", ") || "No open timings available";
        }
    
        if (type === 'closed') {
            // Group days with the "Closed" status
            const closedDays = formattedDays
                .filter(({ status }) => status === "Closed")
                .map(({ day }) => day.charAt(0).toUpperCase() + day.slice(1, 3))
                .join(", ");
    
            return closedDays || "No closed timings";
        }
    
        return "Invalid type";
    };
    
    
    
    
    
    

    return (
        <div className='div-sellar-shop'>
            <img className='seller-image-shop' src={img || 'https://img.freepik.com/free-photo/professional-washer-blue-uniform-washing-luxury-car-with-water-gun-open-air-car-wash_496169-333.jpg'} alt="Shop" />
            <div className='text-heading-toggle'>
                <h5 className='seller-shop' style={{ paddingTop: '0em' }}> {Heading} </h5>
                <Switch
                    checked={checked}
                    onChange={handleChange}
                    offColor="#bcbcbc"
                    onColor="#747EEF"
                    offHandleColor="#ffffff"
                    onHandleColor="#ffffff"
                    handleDiameter={18}
                    uncheckedIcon={false}
                    checkedIcon={false}
                    boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                    activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                    height={22}
                    width={48}
                />
            </div>
            <p className='paragraph-text-shop' style={{display:'flex',alignItems:'center'}}>
            <CiClock2 />
            <p style={{fontSize:13,fontWeight:'400',fontFamily:'"Manrope", sans-serif'}}>
        Timing: {formatTimings(timing, 'open')}
    </p>
    </p>

<div className='icon-div-row'>
    <CiClock2 />
    <p className='icon-patagraph'>
        Closed: {formatTimings(timing, 'closed')}
    </p>
</div>
            <div className='icon-div-row'>
                <CiLocationOn />
                <p className='icon-patagraph'>
                    Location: <span className='location-text'>{location}</span>
                </p>
            </div>
            <h5 className='seller-shop'>AED {Price}</h5>
            <div className='btn-both adding-margin'>
                <ButtonDahbaord handleclick={onlcick} textButton="Delete" width={'100%'} height={38} />
                <ButtonDahbaord handleclick={editOnclick} textButton='Edit' background={'purple'} width={'100%'} height={38} />
            </div>
        </div>
    );
};

export default SellerShopCom;