// import React, { useEffect, useState } from 'react';
// import { NavLink, useNavigate } from 'react-router-dom';
// import Logo from '../../assets/images/image 1.png';
// import { RiDashboardHorizontalLine } from "react-icons/ri";
// import { BsClockHistory,BsPersonWorkspace,BsPatchQuestion } from "react-icons/bs";
// import { IoNotificationsOutline } from "react-icons/io5";
// import { MdKeyboardArrowDown,MdOutlineKeyboardArrowUp } from "react-icons/md";
// import { AiOutlineLogout } from "react-icons/ai";
// import { IoPersonAddSharp ,IoWalletOutline} from "react-icons/io5";
// import { GiChart } from "react-icons/gi";
// import { CiCreditCard2 } from "react-icons/ci";
// import { IoQrCodeOutline } from "react-icons/io5";
// import LogoutPopup from '../../components/logoutpopup/LogoutPopup';
// import { BsFillPersonVcardFill } from "react-icons/bs";
// import '../components/dashboardcom/app.css'
// function AdminSidebar() {
//   const [drawerOpen, setDrawerOpen] = useState(false);
//   const [applicationsOpen, setApplicationsOpen] = useState(true);
//   const [jobOpen, setjobOpen] = useState(false);
//   const [userOpen,setuserOpen] = useState(false)
//   const [CustomerService, setCustomerService] = useState(false);
//   const navigation = useNavigate();
//   const [isModalOpen, setModalOpen] = useState(false);
//   const toggleDrawer = () => {
//     setDrawerOpen(!drawerOpen);
    
//   };  
  
//   console.log('applicationsOpen',applicationsOpen)
//   const togglejob = (e) => {
//     e.stopPropagation();
//     setjobOpen(!jobOpen);
    
//   };
//   const toggleApplications = (e) => {
//     e.stopPropagation();
//     setApplicationsOpen(!applicationsOpen);
//   };
 
//   const handleLogout = () => {
//     setModalOpen(false);
//     localStorage.removeItem("accessTokenAdmin");
//     localStorage.removeItem("superAdmin");
//     localStorage.clear();
//     navigation('/');
//   };
//   return (
//     <>
//       <div className="menu-icon" onClick={toggleDrawer}>
//         &#9776;
//       </div>
//       <div className={`sidebar ${drawerOpen ? 'open' : ''}`}>
//         <div className="logo">
//           <img src={Logo} style={{ height: '100%', width: '100%', objectFit: 'contain' }} />
//         </div>
//         <nav className='navbar-sidebar'>
//           <ul style={{height:'60vh',overflow:'scroll'}}>
//             <li className='li-navbar'>
//               <NavLink to="/super-admin-dashboard" end className={({ isActive }) => (isActive ? 'active-link' : 'navlinkLinkatag')}>
//                 <RiDashboardHorizontalLine style={{ fontSize: 17,marginBottom:4 }} className={({ isActive }) => (isActive ? 'active-link' : 'navlinkLinkatag ')}/>
//                 <p style={{ paddingBottom: 0 }} className={({ isActive }) => (isActive ? 'active-link' : 'navlinkLinkatagnew')}> Dashboard </p>
//               </NavLink>
//             </li>
//             <li className='li-navbar' >
//               <div className={`dropdown-header ${applicationsOpen ? 'open' : ''}`} onClick={toggleApplications}>
//                 <BsFillPersonVcardFill style={{ fontSize: 17,marginBottom:4 }} className={({ isActive }) => (isActive ? 'active-link' : 'navlinkLinkatag ')}/>
//                 <p style={{ paddingBottom: 0,paddingTop:0 }} className="navlinkLinkatag"> Applications </p>
//                 {applicationsOpen ? <MdOutlineKeyboardArrowUp  className="dropdown-arrow" /> : <MdKeyboardArrowDown className="dropdown-arrow" />}
//               </div>
              
//               {applicationsOpen && (
//                 <ul className="dropdown-list">
//                   <li className='li-navbar'  >
//                     <NavLink   style={{fontWeight:400,padding:5}} to="/super-admin-dashboard/pending" end className={({ isActive }) => (isActive ? 'active-link' : 'navlinkLinkatag')}>
//                       <p style={{fontWeight:400,padding:5,userSelect:'none'}} className={({ isActive }) => (isActive ? 'active-link' : 'navlinkLinkatagnew')}> Pending </p>
//                     </NavLink>
//                   </li>
//                   <li className='li-navbar'>
//                     <NavLink style={{fontWeight:400,padding:5}} to="/super-admin-dashboard/approved" end className={({ isActive }) => (isActive ? 'active-link' : 'navlinkLinkatag')}>
//                       <p style={{fontWeight:400,padding:5,userSelect:'none'}} className={({ isActive }) => (isActive ? 'active-link' : 'navlinkLinkatagnew')}> Approved </p>
//                     </NavLink>
//                   </li>
//                   <li className='li-navbar'>
//                     <NavLink style={{fontWeight:400,padding:5}} to="/super-admin-dashboard/rejected" end className={({ isActive }) => (isActive ? 'active-link' : 'navlinkLinkatag')}>
//                       <p style={{fontWeight:400,padding:5,userSelect:'none'}} className={({ isActive }) => (isActive ? 'active-link' : 'navlinkLinkatagnew')}> Rejected </p>
//                     </NavLink>
//                   </li>
//                 </ul>
//               )}
//             </li>
//             <li className='li-navbar' >
//               <div className={`dropdown-header ${jobOpen ? 'open' : ''}`} onClick={togglejob}>
//               <IoWalletOutline  style={{ fontSize: 17,marginBottom:4 }} className={({ isActive }) => (isActive ? 'active-link' : 'navlinkLinkatag ')}/>
//                 <p style={{ paddingBottom: 0,paddingTop:0 }} className="navlinkLinkatag"> Job History </p>
//                 {jobOpen ? <MdOutlineKeyboardArrowUp  className="dropdown-arrow" /> : <MdKeyboardArrowDown className="dropdown-arrow" />}
//               </div>
              
//               {jobOpen && (
//                 <ul className="dropdown-list">
//                   <li className='li-navbar'>
//                     <NavLink style={{fontWeight:400,padding:5}} to="/super-admin-dashboard/customers" end className={({ isActive }) => (isActive ? 'active-link' : 'navlinkLinkatag')}>
//                       <p style={{fontWeight:400,padding:5,userSelect:'none'}} className={({ isActive }) => (isActive ? 'active-link' : 'navlinkLinkatagnew')}> Customers </p>
//                     </NavLink>
//                   </li>
//                   <li className='li-navbar'>
//                     <NavLink style={{fontWeight:400,padding:5}} to="/super-admin-dashboard/companies" end className={({ isActive }) => (isActive ? 'active-link' : 'navlinkLinkatag')}>
//                       <p style={{fontWeight:400,padding:5,userSelect:'none'}} className={({ isActive }) => (isActive ? 'active-link' : 'navlinkLinkatagnew')}> Companies </p>
//                     </NavLink>
//                   </li>
                 
//                 </ul>
//               )}
//             </li>
            
//             <li className='li-navbar'>
//               <NavLink to="/super-admin-dashboard/service-timing" end className={({ isActive }) => (isActive ? 'active-link' : 'navlinkLinkatag')}>
//                 <BsClockHistory style={{ fontSize: 17,marginBottom:4 }} className={({ isActive }) => (isActive ? 'active-link' : 'navlinkLinkatag ')}/>
//                 <p style={{ paddingBottom: 0 }} className={({ isActive }) => (isActive ? 'active-link' : 'navlinkLinkatagnew')}> Service Timing </p>
//               </NavLink>
//             </li>
//             {/* <li className='li-navbar'>
//               <NavLink to="/become-a-seller/shops" end className={({ isActive }) => (isActive ? 'active-link' : 'navlinkLinkatag')}>
//                 <BsPersonWorkspace style={{ fontSize: 20 }} className={({ isActive }) => (isActive ? 'active-link' : 'navlinkLinkatag ')}/>
//                 <p style={{ paddingBottom: 0 }} className={({ isActive }) => (isActive ? 'active-link' : 'navlinkLinkatagnew')}> All Customers </p>
//               </NavLink>
//             </li> */}
//             <li className='li-navbar' >
//               <div className={`dropdown-header ${userOpen ? 'open' : ''}`} onClick={() => setuserOpen(!userOpen)}>
//               <BsPersonWorkspace style={{ fontSize: 17,marginBottom:4 }} className={({ isActive }) => (isActive ? 'active-link' : 'navlinkLinkatag ')}/>
//                 <p style={{ paddingBottom: 0,paddingTop:0 }} className="navlinkLinkatag"> All Users </p>
//                 {userOpen ? <MdOutlineKeyboardArrowUp  className="dropdown-arrow" /> : <MdKeyboardArrowDown className="dropdown-arrow" />}
//               </div>
              
//               {userOpen && (
//                 <ul className="dropdown-list">
//                   <li className='li-navbar'>
//                     <NavLink style={{fontWeight:400,padding:5}} to="/super-admin-dashboard/all-users/all-customers" end className={({ isActive }) => (isActive ? 'active-link' : 'navlinkLinkatag')}>
//                       <p style={{fontWeight:400,padding:5,userSelect:'none'}} className={({ isActive }) => (isActive ? 'active-link' : 'navlinkLinkatagnew')}> All Customers </p>
//                     </NavLink>
//                   </li>
//                   <li className='li-navbar'>
//                     <NavLink style={{fontWeight:400,padding:5}} to="/super-admin-dashboard/all-users/all-companies" end className={({ isActive }) => (isActive ? 'active-link' : 'navlinkLinkatag')}>
//                       <p style={{fontWeight:400,padding:5,userSelect:'none'}} className={({ isActive }) => (isActive ? 'active-link' : 'navlinkLinkatagnew')}> All Shops </p>
//                     </NavLink>
//                   </li>
                 
//                 </ul>
//               )}
//             </li>
//             <li className='li-navbar' >
//               <div className={`dropdown-header ${CustomerService ? 'open' : ''}`} onClick={()=>setCustomerService(!CustomerService)}>
//               <BsPatchQuestion style={{ fontSize: 17,marginBottom:4 }} className={({ isActive }) => (isActive ? 'active-link' : 'navlinkLinkatag ')}/>
//                 <p style={{ paddingBottom: 0,paddingTop:0 }} className="navlinkLinkatag"> Customer Service </p>
//                 {CustomerService ? <MdOutlineKeyboardArrowUp  className="dropdown-arrow" /> : <MdKeyboardArrowDown className="dropdown-arrow" />}
//               </div>
              
//               {CustomerService && (
//                 <ul className="dropdown-list">
//                   <li className='li-navbar'>
//                     <NavLink style={{fontWeight:400,padding:5}} to="/super-admin-dashboard/customer-service-buyer" end className={({ isActive }) => (isActive ? 'active-link' : 'navlinkLinkatag')}>
//                       <p style={{fontWeight:400,padding:5,userSelect:'none'}} className={({ isActive }) => (isActive ? 'active-link' : 'navlinkLinkatagnew')}> Buyer </p>
//                     </NavLink>
//                   </li>
//                   <li className='li-navbar'>
//                     <NavLink style={{fontWeight:400,padding:5}} to="/super-admin-dashboard/customer-service-seller" end className={({ isActive }) => (isActive ? 'active-link' : 'navlinkLinkatag')}>
//                       <p style={{fontWeight:400,padding:5,userSelect:'none'}} className={({ isActive }) => (isActive ? 'active-link' : 'navlinkLinkatagnew')}> Seller </p>
//                     </NavLink>
//                   </li>
                 
//                 </ul>
//               )}
//             </li>
//             <li className='li-navbar'>
//               <NavLink to="/super-admin-dashboard/send-notifications" end className={({ isActive }) => (isActive ? 'active-link' : 'navlinkLinkatag')}>
//                 <IoNotificationsOutline style={{ fontSize: 17,marginBottom:4 }} className={({ isActive }) => (isActive ? 'active-link' : 'navlinkLinkatag ')}/>
//                 <p style={{ paddingBottom: 0 }} className={({ isActive }) => (isActive ? 'active-link' : 'navlinkLinkatagnew')}> Send Notification </p>
//               </NavLink>
//             </li>
//             <li className='li-navbar'>
//               <NavLink to="/super-admin-dashboard/sales" end className={({ isActive }) => (isActive ? 'active-link' : 'navlinkLinkatag')}>
//                 <GiChart style={{ fontSize: 17,marginBottom:4 }} className={({ isActive }) => (isActive ? 'active-link' : 'navlinkLinkatag ')}/>
//                 <p style={{ paddingBottom: 0 }} className={({ isActive }) => (isActive ? 'active-link' : 'navlinkLinkatagnew')}> Sales </p>
//               </NavLink>
//             </li>
//             <li className='li-navbar'>
//               <NavLink to="/super-admin-dashboard/service-fee" end className={({ isActive }) => (isActive ? 'active-link' : 'navlinkLinkatag')}>
//                 <CiCreditCard2 style={{ fontSize: 17,marginBottom:4 }} className={({ isActive }) => (isActive ? 'active-link' : 'navlinkLinkatag ')}/>
//                 <p style={{ paddingBottom: 0 }} className={({ isActive }) => (isActive ? 'active-link' : 'navlinkLinkatagnew')}> Service Fee </p>
//               </NavLink>
//             </li>
//             <li className='li-navbar'>
//               <NavLink to="/super-admin-dashboard/promo-code" end className={({ isActive }) => (isActive ? 'active-link' : 'navlinkLinkatag')}>
//                 <IoQrCodeOutline style={{ fontSize: 17,marginBottom:4 }} className={({ isActive }) => (isActive ? 'active-link' : 'navlinkLinkatag ')}/>
//                 <p style={{ paddingBottom: 0 }} className={({ isActive }) => (isActive ? 'active-link' : 'navlinkLinkatagnew')}> Promo Code </p>
//               </NavLink>
//             </li>
//           </ul>
//           <ul className='ul-list-bottom' style={{marginTop:'5em'}}>
//             <li className='li-navbar'>
//               <NavLink to="/super-admin-dashboard/add-agents" end className={({ isActive }) => (isActive ? 'active-link' : 'navlinkLinkatag')}>
//                 <IoPersonAddSharp style={{ fontSize: 14,marginBottom:4 }} className={({ isActive }) => (isActive ? 'active-link' : 'navlinkLinkatag ')}/>
//                 <p style={{ paddingBottom: 0 }} className={({ isActive }) => (isActive ? 'active-link' : 'navlinkLinkatagnew')}> Add Agent </p>
//               </NavLink>
//             </li>
//             <li className='li-navbar'>
//               <NavLink onClick={() => setModalOpen(true)} to='' end className='navlinkLinkatag'>
//                 <AiOutlineLogout style={{ fontSize: 17,marginBottom:4 }} className={({ isActive }) => (isActive ? 'active-link' : 'navlinkLinkatag ')}/>
//                 <p style={{ paddingBottom: 0 }} className={({ isActive }) => (isActive ? 'active-link' : 'navlinkLinkatagnew')}> Logout </p>
//               </NavLink>
//               {/* <LogoutPopup HandleLogout={HandleLogout} show={modalShow} onHide={() => setModalShow(false)} /> */}
//               <LogoutPopup isOpen={isModalOpen} 
//         onClose={() => setModalOpen(false)} 
//         onLogout={handleLogout}/>
//             </li>
//           </ul>
//         </nav>
//       </div>
//     </>
//   );
// }

// export default AdminSidebar;
import React, { useEffect, useState } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import Logo from '../../assets/images/image 1.png';
import { RiDashboardHorizontalLine } from "react-icons/ri";
import { BsClockHistory, BsPersonWorkspace, BsPatchQuestion } from "react-icons/bs";
import { IoNotificationsOutline } from "react-icons/io5";
import { MdKeyboardArrowDown, MdOutlineKeyboardArrowUp } from "react-icons/md";
import { AiOutlineLogout } from "react-icons/ai";
import { IoPersonAddSharp, IoWalletOutline } from "react-icons/io5";
import { GiChart } from "react-icons/gi";
import { CiCreditCard2 } from "react-icons/ci";
import { IoQrCodeOutline } from "react-icons/io5";
import LogoutPopup from '../../components/logoutpopup/LogoutPopup';
import { BsFillPersonVcardFill } from "react-icons/bs";
import { MdOutlineKeyboardArrowRight } from "react-icons/md";



import '../components/dashboardcom/app.css';

function AdminSidebar() {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const navigation = useNavigate();
  const [isModalOpen, setModalOpen] = useState(false);

  // Initialize dropdown states from localStorage or default to false
  const [applicationsOpen, setApplicationsOpen] = useState(
    localStorage.getItem('applicationsOpen') === 'true'
  );
  const [jobOpen, setJobOpen] = useState(
    localStorage.getItem('jobOpen') === 'true'
  );
  const [userOpen, setUserOpen] = useState(
    localStorage.getItem('userOpen') === 'true'
  );
  const [CustomerService, setCustomerService] = useState(
    localStorage.getItem('CustomerService') === 'true'
  );

  // Update localStorage whenever a dropdown state changes
  useEffect(() => {
    localStorage.setItem('applicationsOpen', applicationsOpen);
  }, [applicationsOpen]);

  useEffect(() => {
    localStorage.setItem('jobOpen', jobOpen);
  }, [jobOpen]);

  useEffect(() => {
    localStorage.setItem('userOpen', userOpen);
  }, [userOpen]);

  useEffect(() => {
    localStorage.setItem('CustomerService', CustomerService);
  }, [CustomerService]);

  const toggleDrawer = () => {
    setDrawerOpen(!drawerOpen);
  };

  const toggleApplications = (e) => {
    e.stopPropagation();
    setApplicationsOpen(!applicationsOpen);
    setJobOpen(false);
    setUserOpen(false);
    setCustomerService(false);
  };

  const toggleJob = (e) => {
    e.stopPropagation();
    setJobOpen(!jobOpen);
    setApplicationsOpen(false);
    setUserOpen(false);
    setCustomerService(false);
  };

  const toggleUser = (e) => {
    e.stopPropagation();
    setUserOpen(!userOpen);
    setApplicationsOpen(false);
    setJobOpen(false);
    setCustomerService(false);
  };

  const toggleCustomerService = (e) => {
    e.stopPropagation();
    setCustomerService(!CustomerService);
    setApplicationsOpen(false);
    setJobOpen(false);
    setUserOpen(false);
  };

  const handleLogout = () => {
    setModalOpen(false);
    localStorage.removeItem("accessTokenAdmin");
    localStorage.removeItem("superAdmin");
    localStorage.clear();
    navigation('/');
  };

  return (
    <>
      <div className="menu-icon" onClick={toggleDrawer}>
        &#9776;
      </div>
      <div className={`sidebar ${drawerOpen ? 'open' : ''}`}>
        <div className="logo">
          <img src={Logo} style={{ height: '100%', width: '100%', objectFit: 'contain' }} alt="Logo" />
        </div>
        <nav className='navbar-sidebar'>
          <ul style={{ height: '60vh', overflow: 'scroll' }}>
            <li className='li-navbar'>
              <NavLink to="/super-admin-dashboard" end className={({ isActive }) => (isActive ? 'active-link' : 'navlinkLinkatag')}>
                <RiDashboardHorizontalLine style={{ fontSize: 17, marginBottom: 4 }} className={({ isActive }) => (isActive ? 'active-link' : 'navlinkLinkatag ')} />
                <p style={{ paddingBottom: 0 }} className={({ isActive }) => (isActive ? 'active-link' : 'navlinkLinkatagnew')}> Dashboard </p>
              </NavLink>
            </li>
            <li className='li-navbar'>
              <div className={`dropdown-header ${applicationsOpen ? 'open' : ''}`} onClick={toggleApplications}>
                <BsFillPersonVcardFill style={{ fontSize: 17, marginBottom: 4 }} className={({ isActive }) => (isActive ? 'active-link' : 'navlinkLinkatag ')} />
                <p style={{ paddingBottom: 0, paddingTop: 0 }} className="navlinkLinkatag"> Applications </p>
                {applicationsOpen ? <MdOutlineKeyboardArrowUp className="dropdown-arrow" /> : <MdOutlineKeyboardArrowRight className="dropdown-arrow" />}
              </div>
              {applicationsOpen && (
                <ul className="dropdown-list">
                  <li className='li-navbar'>
                    <NavLink style={{ fontWeight: 400, padding: 5 }} to="/super-admin-dashboard/pending" end className={({ isActive }) => (isActive ? 'active-link' : 'navlinkLinkatag')}>
                      <p style={{ fontWeight: 400, padding: 5, userSelect: 'none' }} className={({ isActive }) => (isActive ? 'active-link' : 'navlinkLinkatagnew')}> Pending </p>
                    </NavLink>
                  </li>
                  <li className='li-navbar'>
                    <NavLink style={{ fontWeight: 400, padding: 5 }} to="/super-admin-dashboard/approved" end className={({ isActive }) => (isActive ? 'active-link' : 'navlinkLinkatag')}>
                      <p style={{ fontWeight: 400, padding: 5, userSelect: 'none' }} className={({ isActive }) => (isActive ? 'active-link' : 'navlinkLinkatagnew')}> Approved </p>
                    </NavLink>
                  </li>
                  <li className='li-navbar'>
                    <NavLink style={{ fontWeight: 400, padding: 5 }} to="/super-admin-dashboard/rejected" end className={({ isActive }) => (isActive ? 'active-link' : 'navlinkLinkatag')}>
                      <p style={{ fontWeight: 400, padding: 5, userSelect: 'none' }} className={({ isActive }) => (isActive ? 'active-link' : 'navlinkLinkatagnew')}> Rejected </p>
                    </NavLink>
                  </li>
                </ul>
              )}
            </li>
            <li className='li-navbar'>
              <div className={`dropdown-header ${jobOpen ? 'open' : ''}`} onClick={toggleJob}>
                <IoWalletOutline style={{ fontSize: 17, marginBottom: 4 }} className={({ isActive }) => (isActive ? 'active-link' : 'navlinkLinkatag ')} />
                <p style={{ paddingBottom: 0, paddingTop: 0 }} className="navlinkLinkatag"> Job History </p>
                {jobOpen ? <MdOutlineKeyboardArrowUp className="dropdown-arrow" /> : <MdOutlineKeyboardArrowRight className="dropdown-arrow" />}
              </div>
              {jobOpen && (
                <ul className="dropdown-list">
                  <li className='li-navbar'>
                    <NavLink style={{ fontWeight: 400, padding: 5 }} to="/super-admin-dashboard/customers" end className={({ isActive }) => (isActive ? 'active-link' : 'navlinkLinkatag')}>
                      <p style={{ fontWeight: 400, padding: 5, userSelect: 'none' }} className={({ isActive }) => (isActive ? 'active-link' : 'navlinkLinkatagnew')}> Customers </p>
                    </NavLink>
                  </li>
                  <li className='li-navbar'>
                    <NavLink style={{ fontWeight: 400, padding: 5 }} to="/super-admin-dashboard/companies" end className={({ isActive }) => (isActive ? 'active-link' : 'navlinkLinkatag')}>
                      <p style={{ fontWeight: 400, padding: 5, userSelect: 'none' }} className={({ isActive }) => (isActive ? 'active-link' : 'navlinkLinkatagnew')}> Shops </p>
                    </NavLink>
                  </li>
                </ul>
              )}
            </li>
            <li className='li-navbar'>
              <NavLink to="/super-admin-dashboard/service-timing" end className={({ isActive }) => (isActive ? 'active-link' : 'navlinkLinkatag')}>
                <BsClockHistory style={{ fontSize: 17, marginBottom: 4 }} className={({ isActive }) => (isActive ? 'active-link' : 'navlinkLinkatag ')} />
                <p style={{ paddingBottom: 0 }} className={({ isActive }) => (isActive ? 'active-link' : 'navlinkLinkatagnew')}> Service Timing </p>
              </NavLink>
            </li>
            <li className='li-navbar'>
              <div className={`dropdown-header ${userOpen ? 'open' : ''}`} onClick={toggleUser}>
                <BsPersonWorkspace style={{ fontSize: 17, marginBottom: 4 }} className={({ isActive }) => (isActive ? 'active-link' : 'navlinkLinkatag ')} />
                <p style={{ paddingBottom: 0, paddingTop: 0 }} className="navlinkLinkatag"> All Users </p>
                {userOpen ? <MdOutlineKeyboardArrowUp className="dropdown-arrow" /> : <MdOutlineKeyboardArrowRight className="dropdown-arrow" />}
              </div>
              {userOpen && (
                <ul className="dropdown-list">
                  <li className='li-navbar'>
                    <NavLink style={{ fontWeight: 400, padding: 5 }} to="/super-admin-dashboard/all-users/all-customers" end className={({ isActive }) => (isActive ? 'active-link' : 'navlinkLinkatag')}>
                      <p style={{ fontWeight: 400, padding: 5, userSelect: 'none' }} className={({ isActive }) => (isActive ? 'active-link' : 'navlinkLinkatagnew')}> All Customers </p>
                    </NavLink>
                  </li>
                  <li className='li-navbar'>
                    <NavLink style={{ fontWeight: 400, padding: 5 }} to="/super-admin-dashboard/all-users/all-companies" end className={({ isActive }) => (isActive ? 'active-link' : 'navlinkLinkatag')}>
                      <p style={{ fontWeight: 400, padding: 5, userSelect: 'none' }} className={({ isActive }) => (isActive ? 'active-link' : 'navlinkLinkatagnew')}> All Shops </p>
                    </NavLink>
                  </li>
                </ul>
              )}
            </li>
            <li className='li-navbar'>
              <div className={`dropdown-header ${CustomerService ? 'open' : ''}`} onClick={toggleCustomerService}>
                <BsPatchQuestion style={{ fontSize: 17, marginBottom: 4 }} className={({ isActive }) => (isActive ? 'active-link' : 'navlinkLinkatag ')} />
                <p style={{ paddingBottom: 0, paddingTop: 0 }} className="navlinkLinkatag"> Customer Service </p>
                {CustomerService ? <MdOutlineKeyboardArrowUp className="dropdown-arrow" /> : <MdOutlineKeyboardArrowRight className="dropdown-arrow" />}
              </div>
              {CustomerService && (
                <ul className="dropdown-list">
                  <li className='li-navbar'>
                    <NavLink style={{ fontWeight: 400, padding: 5 }} to="/super-admin-dashboard/customer-service-buyer" end className={({ isActive }) => (isActive ? 'active-link' : 'navlinkLinkatag')}>
                      <p style={{ fontWeight: 400, padding: 5, userSelect: 'none' }} className={({ isActive }) => (isActive ? 'active-link' : 'navlinkLinkatagnew')}> Buyer </p>
                    </NavLink>
                  </li>
                  <li className='li-navbar'>
                    <NavLink style={{ fontWeight: 400, padding: 5 }} to="/super-admin-dashboard/customer-service-seller" end className={({ isActive }) => (isActive ? 'active-link' : 'navlinkLinkatag')}>
                      <p style={{ fontWeight: 400, padding: 5, userSelect: 'none' }} className={({ isActive }) => (isActive ? 'active-link' : 'navlinkLinkatagnew')}> Seller </p>
                    </NavLink>
                  </li>
                </ul>
              )}
            </li>
            <li className='li-navbar'>
              <NavLink to="/super-admin-dashboard/send-notifications" end className={({ isActive }) => (isActive ? 'active-link' : 'navlinkLinkatag')}>
                <IoNotificationsOutline style={{ fontSize: 17, marginBottom: 4 }} className={({ isActive }) => (isActive ? 'active-link' : 'navlinkLinkatag ')} />
                <p style={{ paddingBottom: 0 }} className={({ isActive }) => (isActive ? 'active-link' : 'navlinkLinkatagnew')}> Send Notification </p>
              </NavLink>
            </li>
            <li className='li-navbar'>
              <NavLink to="/super-admin-dashboard/sales" end className={({ isActive }) => (isActive ? 'active-link' : 'navlinkLinkatag')}>
                <GiChart style={{ fontSize: 17, marginBottom: 4 }} className={({ isActive }) => (isActive ? 'active-link' : 'navlinkLinkatag ')} />
                <p style={{ paddingBottom: 0 }} className={({ isActive }) => (isActive ? 'active-link' : 'navlinkLinkatagnew')}> Sales </p>
              </NavLink>
            </li>
            <li className='li-navbar'>
              <NavLink to="/super-admin-dashboard/service-fee" end className={({ isActive }) => (isActive ? 'active-link' : 'navlinkLinkatag')}>
                <CiCreditCard2 style={{ fontSize: 17, marginBottom: 4 }} className={({ isActive }) => (isActive ? 'active-link' : 'navlinkLinkatag ')} />
                <p style={{ paddingBottom: 0 }} className={({ isActive }) => (isActive ? 'active-link' : 'navlinkLinkatagnew')}> Service Fee </p>
              </NavLink>
            </li>
            <li className='li-navbar'>
              <NavLink to="/super-admin-dashboard/promo-code" end className={({ isActive }) => (isActive ? 'active-link' : 'navlinkLinkatag')}>
                <IoQrCodeOutline style={{ fontSize: 17, marginBottom: 4 }} className={({ isActive }) => (isActive ? 'active-link' : 'navlinkLinkatag ')} />
                <p style={{ paddingBottom: 0 }} className={({ isActive }) => (isActive ? 'active-link' : 'navlinkLinkatagnew')}> Promo Code </p>
              </NavLink>
            </li>
          </ul>
          <ul className='ul-list-bottom' style={{ marginTop: '5em' }}>
            <li className='li-navbar'>
              <NavLink to="/super-admin-dashboard/add-agents" end className={({ isActive }) => (isActive ? 'active-link' : 'navlinkLinkatag')}>
                <IoPersonAddSharp style={{ fontSize: 14, marginBottom: 4 }} className={({ isActive }) => (isActive ? 'active-link' : 'navlinkLinkatag ')} />
                <p style={{ paddingBottom: 0 }} className={({ isActive }) => (isActive ? 'active-link' : 'navlinkLinkatagnew')}> Add Agent </p>
              </NavLink>
            </li>
            <li className='li-navbar'>
              <NavLink onClick={() => setModalOpen(true)} to='' end className='navlinkLinkatag'>
                <AiOutlineLogout style={{ fontSize: 17, marginBottom: 4 }} className={({ isActive }) => (isActive ? 'active-link' : 'navlinkLinkatag ')} />
                <p style={{ paddingBottom: 0 }} className={({ isActive }) => (isActive ? 'active-link' : 'navlinkLinkatagnew')}> Logout </p>
              </NavLink>
              <LogoutPopup isOpen={isModalOpen} onClose={() => setModalOpen(false)} onLogout={handleLogout} />
            </li>
          </ul>
        </nav>
      </div>
    </>
  );
}

export default AdminSidebar;