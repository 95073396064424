import React, { useState } from 'react';
import axios from 'axios'; // Make sure to import axios
import ImagePro from '../../../assets/images/app.logo.jpeg';
import './app.css';
import PopupCustomer from '../jobhistory/popupCustomerCom';
import InvoicePopup from '../jobhistory/InvoicePopup';


const TopCustomer = ({ ID, Avatar, name, textpopupBtn, Item, spent, onDeleteInoive, Completed, show, vist, carType, carPlate, orderId, orderTime, status, paymentId }) => {
  const [modalShow, setModalShow] = useState(false);
  const [invoiceShow, setInvoiceShow] = useState(false);
  const [popupData, setPopupData] = useState({});
  const handleViewDocumentsClick = () => {
    const token = localStorage.getItem('accessTokenAdmin');
    setModalShow(true);
    axios.get(`https://backend.washta.com/api/admin/orders?limit=5&customerId=${ID}`, {
      headers: {
        Authorization: `Bearer ${token}` // Include the token in the request headers
      }
    })
    .then(response => {
      
      setPopupData(response.data.data); // Update the state with the fetched data
      // setModalShow(true);
    })
    .catch(error => {
      console.error('Error fetching data:', error);
    });
  };
  const handleDeleteInvoice = async () => {
    const token = localStorage.getItem('accessTokenAdmin');
    const apiUrl = `https://backend.washta.com/api/admin/bookings/${onDeleteInoive}`;
    
    try {
      const response = await axios.delete(apiUrl, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
  
      if (response.status === 200) {
        setModalShow(false);
      } else {
        // Show error toast
       alert('Failed to delete customer.');
      }
    } catch (err) {
      alert('Error deleting customer:', err);
     
    }
  };
  const handleViewInvoice = () => {
    setModalShow(false);
    setInvoiceShow(true);
  };

  return (
    <div className='tope-customer-com-main'>
      <div className='second-row-customer' style={{ cursor: 'pointer' }} onClick={handleViewDocumentsClick}>
        <img src={Avatar || ImagePro} className='image-pro-companies' style={{ boxShadow: '0px 0px 3px 0px #333', borderRadius: '21%' }} />
        <div className='top-custorm-width'>
          <h5 className='h5-class-top-class'>{name}</h5>
          <p className='paragprh-customer-com'>{vist}</p>
        </div>
      </div>
      {show && (
        <div className='second-row-customer' style={{ paddingTop: 16, cursor: 'pointer' }} onClick={handleViewDocumentsClick}>
          <p className='paragprh-customer-com'>Status</p>
          <p className='heading-sub-customer-com'>{Completed}</p>
        </div>
      )}
      <PopupCustomer
      onDeleteInoive={handleDeleteInvoice}
        showBtn={true}
        HandleLogout={''}
        show={modalShow}
        onHide={() => setModalShow(false)}
        popupData={popupData}
        textpopupBtn={textpopupBtn}
        onViewInvoice={handleViewInvoice}
      />
      <InvoicePopup
      showFields={false}
        show={invoiceShow}
        onClose={() => setInvoiceShow(false)}
        invoiceData={Item}
      />
      <div className='second-row-customer' style={{ paddingTop: show ? 5 : 15, border: 'none', cursor: 'pointer' }} onClick={handleViewDocumentsClick}>
        <p className='paragprh-customer-com'>Total Spent</p>
        <p className='heading-sub-customer-com'>{spent} <span style={{ fontSize: 9 }}>AED</span></p>
      </div>
    </div>
  );
}

export default TopCustomer;
