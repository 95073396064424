import React from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, IconButton, Typography } from '@mui/material';
import { IoCloseOutline } from "react-icons/io5";
import ButtonDahbaord from 'components/ButtonDahbaord/ButtonDahbaord';
import { formatDate, formatTimeInTimezone } from 'pages/utils/formdatedynamics';

const calculateFinalCost = (popupData) => {
  let cost = parseFloat(popupData?.cost || 0);
  let vatFee = cost * 0.05; 
  let serviceFee = 0;
  let discount = 0;
  let promoCodeText = "No Promo Code Applied";
  if (popupData?.serviceFee?.length) {
    const feeData = popupData.serviceFee[0];
    if (feeData.feeType === 'percentage') {
      serviceFee = (parseFloat(feeData.WashtaFees) / 100) * cost;
    } else {
      serviceFee = parseFloat(feeData.WashtaFees);
    }
  }

  // Process promo code discount
  if (popupData?.promoCode) {
    if (popupData.promoCode.Discounttype === 'percentage') {
      discount = (parseFloat(popupData.promoCode.discount) / 100) * cost;
      promoCodeText = `${popupData.promoCode.promoCode} (${popupData.promoCode.discount}% off)`;
    } else {
      discount = parseFloat(popupData.promoCode.discount);
      promoCodeText = `${popupData.promoCode.promoCode} (Fixed Discount: ${discount} AED)`;
    }
  }

  let totalReduction = serviceFee + vatFee + discount;
  let finalCost = cost - totalReduction;

  return { 
    finalCost: finalCost.toFixed(2), 
    totalReduction: totalReduction.toFixed(2),
    vatFee: vatFee.toFixed(2),
    serviceFee: serviceFee.toFixed(2),
    discount: discount.toFixed(2),
    promoCodeText
  };
};

const PopupCompanies = ({ show, onHide, popupData, onViewInvoice }) => {
  console.log('popupData', popupData);
  
  const { finalCost, totalReduction, vatFee, serviceFee, discount, promoCodeText } = calculateFinalCost(popupData);

  return (
    <Dialog
      open={show}
      onClose={onHide}
      fullWidth
      maxWidth="lg"
    >
      <DialogTitle sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        {popupData?.shopId?.shopName}
        <IconButton onClick={onHide}>
          <IoCloseOutline />
        </IconButton>
      </DialogTitle>

      <DialogContent>
        <div className='popup-pending-appliction'>
          <div className='div-left-popup' style={{ width: '50%' }}>
            <Typography variant="h6">Shop Details</Typography>
            {[
              { label: 'Shop Name', value: popupData?.shopId?.shopName || 'Buyer Name:' },
              { label: 'Shop Price', value: popupData?.cost + " AED " || 'Not defined:' },
              { label: 'Service', value: (popupData?.vehicleId?.vehicleManufacturer || '') + ' ' + (popupData?.vehicleId?.vehicleName || '') },
              { label: 'Location', value: popupData?.shopId?.location?.text || '' },
              { label: 'Vat 5%', value: vatFee + " AED" || '' },
              { label: `Washta Fee (${popupData?.serviceFee[0]?.WashtaFees} ${popupData?.serviceFee[0]?.feeType === 'percentage' ? '%' : ' AED'})`, value: serviceFee + " AED" || '' },
            ].map((item, index) => (
              <div key={index} className='popup-inputfeild-application'>
                <p className='vat-number-api'>{item.label}:</p>
                <input className='valt-number-input-feild' placeholder='text' value={item.value} readOnly />
              </div>
            ))}
          </div>
          <div className='div-left-popup' style={{ width: '50%' }}>
            <Typography variant="h6">Order Details</Typography>
            {[
              { label: 'Payment ID', value: popupData?.paymentId || '' },
              { label: 'Order Date', value: formatDate(popupData?.createdAt) + ' At ' + formatTimeInTimezone(popupData?.createdAt) || '' },
              { label: 'Billing Status', value: popupData?.isPaid ? 'Paid' : 'Non-Paid' },
              { label: 'Promo Code', value: promoCodeText },
              { label: 'Promo Code Discount', value: discount + " AED" || '' },
              { label: 'Total Deduction', value: totalReduction + " AED" || '' },
             
            ].map((item, index) => (
              <div key={index} className='popup-inputfeild-application'>
                <p className='vat-number-api'>{item.label}:</p>
                <input className='valt-number-input-feild' placeholder='text' value={item.value} readOnly />
              </div>
            ))}
          </div>
        </div>
      </DialogContent>

      <DialogActions>
        <div className='paymanet-div-super-admin'>
          <Typography variant="body2">Total Price</Typography>
          <Typography variant="body1">
            {finalCost} <span style={{ fontSize: 12 }}>AED</span>
          </Typography>
        </div>
       
        
       
        <div className='second-payment-div'>
          <ButtonDahbaord
            handleclick={onViewInvoice}
            textButton={'View Invoice'}
            height={32}
            width={"24%"}
            background='transparent'
          />
          <ButtonDahbaord
            textButton={'Delete'}
            height={32}
            width={"18%"}
            background='purple'
          />
        </div>
      </DialogActions>
    </Dialog>
  );
};

export default PopupCompanies;
