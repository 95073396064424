// import React, { useEffect, useState } from 'react';
// import { ImCross } from "react-icons/im";
// import { ToastContainer, toast } from 'react-toastify';
// import { MdOutlineAttachment } from "react-icons/md";
// import 'react-toastify/dist/ReactToastify.css';
// import { TimePicker, Space } from 'antd';
// import 'antd/dist/reset.css';
// import moment from 'moment';
// import Switch from 'react-switch';
// import { GoogleMap, LoadScript, Marker, Circle } from '@react-google-maps/api';
// import axios from 'axios';
// import './popup.css';
// import Autocomplete from 'react-google-autocomplete';
// const PopupSellar = ({ Cross, id ,fetchData,editData}) => {
//   console.log('editData',editData)
//   const [step, setStep] = useState(1);
//   const [formData, setFormData] = useState({
//     Owner: id,
//     shopName: '',
//     shopDetails: '',
//     cost: '',
//     uploadCover: null,
//     city: '',
//     isActive: true,
//     radius: '',
//     location: {
//       country_city: "UAE , Dubai",
//       text: "Istiqlal Street - near Immigration Office - Bu Danig - Sharjah - United Arab Emirates",
//       lat: 67.0999638,
//       long: 24.9214989,
//     },
//     estimatedServiceTime: "",
//     timing: {
//       monday: { open: false, from: '', to: '' },
//       tuesday: { open: false, from: '', to: '' },
//       wednesday: { open: false, from: '', to: '' },
//       thursday: { open: false, from: '', to: '' },
//       friday: { open: false, from: '', to: '' },
//       saturday: { open: false, from: '', to: '' },
//       sunday: { open: false, from: '', to: '' },
//     },
//   });
//   const [error, setError] = useState('');

//   const [uploading, setUploading] = useState(false);
//   const [fileName, setFileName] = useState('');

//  const token = localStorage.getItem('accessToken')
//  const handleTimeChange = (day, type, time) => {
//     setFormData((prev) => ({
//       ...prev,
//       timing: {
//         ...prev.timing,
//         [day]: {
//           ...prev.timing[day],
//           [type]: time ? time.toISOString() : '', // Save time as ISO string
//           open: true, // Set open to true when times are selected
//         },
//       },
//     }));
//   };

//   const handleFileChange = (e) => {
//     const file = e.target.files[0];
//     setFormData({ ...formData, uploadCover: file });
//     setFileName(file ? file.name : '');
//   };
//   useEffect(() => {
//     if (editData) {
//       setFormData({
//         ...formData,
//         Owner: editData.Owner,
//         shopName: editData.shopName,
//         shopDetails: editData.shopDetails,
//         cost: editData.cost,
//         uploadCover: editData.coverImage, // Handle if coverImage should be uploaded
//         city: '', // Update if you have a field for city
//         isActive: editData.isActive,
//         radius: editData.radius,
//         location: {
//           country_city: "UAE , Dubai", // Can be updated if city info is available
//           text: editData.location.text,
//           lat: editData.location.coordinates[1],
//           long: editData.location.coordinates[0],
//         },
//         estimatedServiceTime: editData.estimatedServiceTime,
//         timing: editData.timing, // If timing data is present in editData
//       });
//     }
//   }, [editData]);

// const handleActiveChange = (day) => {
//     setFormData((prev) => ({
//       ...prev,
//       timing: {
//         ...prev.timing,
//         [day]: {
//           ...prev.timing[day],
//           open: !prev.timing[day].open,
//           from: '',
//           to: '',
//         },
//       },
//     }));
//   };


// const handlePlaceSelected = (place) => {
//     if (place && place.geometry) {
//       const addressComponents = place.address_components;
      
//       // Extract city and country from the address components
//       const city = addressComponents.find(component => 
//         component.types.includes("locality") || component.types.includes("administrative_area_level_1")
//       )?.long_name || '';
      
//       const country = addressComponents.find(component => 
//         component.types.includes("country")
//       )?.long_name || '';
  
//       setFormData((prevData) => ({
//         ...prevData,
//         city: city, 
//         location: {
//           country_city: `${country}, ${city}`, // Fixing template literal syntax
//           text: place.formatted_address, 
//           lat: place.geometry.location.lat(), 
//           long: place.geometry.location.lng(),
//         },
//         markerPosition: {
//           lat: place.geometry.location.lat(),
//           lng: place.geometry.location.lng(),
//         },
//         radius: formData.radius || '5', // Default radius if not specified
//       }));
//     }
//   };
  

//   const handleMapClick = (e) => {
//     const lat = e.latLng.lat();
//     const lng = e.latLng.lng();
//     setFormData({
//       ...formData,
//       markerPosition: { lat, lng },
//     });
//   };

//   const geocodeAddress = (address) => {
//     const geocoder = new window.google.maps.Geocoder();
//     console.log("Geocoding address:", address);  // Log the address
//     geocoder.geocode({ address: address }, (results, status) => {
//       console.log("Geocode results:", results);  // Log the results
//       console.log("Geocode status:", status);    // Log the status
//       if (status === 'OK') {
//         const position = results[0].geometry.location;
//         setFormData({
//           ...formData,
//           markerPosition: { lat: position.lat(), lng: position.lng() },
//         });
//       } else {
//         console.log('Geocode was not successful for the following reason: ' + status);
//       }
//     });
//   };

//   const handleChange = (e) => {
//     const { name, value } = e.target;
//     setFormData({ ...formData, [name]: value });
//   };
//   const formatTime = (time) => {
//     if (!time) return ''; // Handle empty time
//     const date = new Date(time); // Convert to Date object if in ISO format
//     const hours = String(date.getHours()).padStart(2, '0');
//     const minutes = String(date.getMinutes()).padStart(2, '0');
//     return `${hours}:${minutes}`; // Return formatted time as HH:mm
//   };
// const handleSubmit = async (e) => {
//   e.preventDefault();

//   // Validate required fields
//   if (!validateStep1()) return;

//   if (!formData.uploadCover && !editData?.coverImage) {
//     toast.error('Please select an image to upload or retain the existing image');
//     return;
//   }

//   setUploading(true);

//   let documentUrl = editData?.coverImage; // Use existing image URL by default

//   try {
//     // Upload the image only if a new one is provided
//     if (formData.uploadCover) {
//       const uploadFormData = new FormData();
//       uploadFormData.append('file', formData.uploadCover);

//       const uploadResponse = await axios.post('https://backend.washta.com/api/uploadFile', uploadFormData, {
//         headers: {
//           'Content-Type': 'multipart/form-data',
//           Authorization: `Bearer ${token}`,
//         },
//       });

//       documentUrl = uploadResponse.data.data.path; // Update with new uploaded image URL
//     }

//     // Construct the payload for the API
    
//     const payload = {
//       Owner:formData.Owner,
//       shopName: formData.shopName,
//       coverImage: documentUrl, // Use the new or existing image URL
//       isActive: true,
//       shopDetails: formData.shopDetails,
//       location: {
//         text: formData.location.text,
//         coordinates: [formData.location.long, formData.location.lat],
//       },
//       timing: Object.keys(formData.timing).reduce((acc, day) => {
//         acc[day] = {
//           open: formData.timing[day].open,
//           from: formatTime(formData.timing[day].from), // Format 'from' time
//           to: formatTime(formData.timing[day].to),     // Format 'to' time
//         };
//         return acc;
//       }, {}),
//       cost: formData.cost,
//       radius: formData.radius,
//       estimatedServiceTime: formData?.estimatedServiceTime,
//       markerPosition:{
//         lat:formData.location.lat,
//         lng:formData.location.long
//     },
//     };
//     console.log('payload',payload)
//     // Determine endpoint and method
//     const apiEndpoint = editData ? `/api/seller/shop/${editData._id}` : '/api/seller/shop';
//     const httpMethod = editData ? 'PATCH' : 'POST';

//     // Make the API call
//     const response = await axios({
//       method: httpMethod,
//       url: `https://backend.washta.com${apiEndpoint}`,
//       data: payload,
//       headers: {
//         'Content-Type': 'application/json',
//         Authorization: `Bearer ${token}`,
//       },
//     });

//     if (response.status === 200) {
//       toast.success(editData ? 'Shop details updated successfully!' : 'Shop details submitted successfully!');
//       fetchData(); // Refresh data
//       Cross(); // Close popup
//     } else {
//       toast.error('Failed to submit shop details');
//     }
//   } catch (error) {
//     if (error.response?.status === 429) {
//       toast.error('Too many requests, please try again later.');
//     } else {
//       console.error('Error:', error);
//       toast.error('Error submitting shop details');
//     }
//   } finally {
//     setUploading(false);
//   }
// };




// // Validate Step 1
// const validateStep1 = () => {
//   if (!formData.shopName || !formData.shopDetails || !formData.cost || !formData.uploadCover) {
//     toast.error('Please fill all fields and upload an image');
//     return false;
//   }
//   setError('');  // Clear error message
//   return true;
// };

//   const validateStep2 = () => {
//     let isAnyActive = false; 
//     let hasError = false; 
//     let errorMessages = []; // To accumulate error messages
    
//     for (const day in formData.timing) {
//       const { from, to, open } = formData.timing[day];
      
//       if (open) { // Check if the day is active
//         isAnyActive = true;
//         if (!from || !to) {
//           const errorMessage = `Please fill in start and end times for ${day.charAt(0).toUpperCase() + day.slice(1)}.`;
//           errorMessages.push(errorMessage); // Accumulate error messages
//           setError(errorMessage); // Set the error state for the latest error
//           toast.error(errorMessage); // Show toast notification for the latest error
//           hasError = true; // Set the error flag
//         }
//       }
//     }
  
//     // Check if at least one day is active
//     if (!isAnyActive) {
//       const errorMessage = 'Please activate at least one day.';
//       errorMessages.push(errorMessage); // Accumulate error messages
//       setError(errorMessage); // Set the error state
//       toast.error(errorMessage); // Show toast notification
//       return false; // Prevent navigation
//     }
  
//     // If there is an error, prevent navigation
//     if (hasError) {
//       return false; // Prevent navigation if there are errors
//     }
  
//     // If you want to show all accumulated error messages:
//     if (errorMessages.length > 0) {
//       toast.error(errorMessages.join('\n')); // Show all accumulated error messages at once
//     }
  
//     setError(''); // Clear error if all active days are valid
//     return true; // Pass validation
//   };
  
  
  
//   const validateStep3 = () => {
//     if (!formData.selectedCity || !formData.radius) {
//       toast.error('Please fill all fields');
//       return false;
//     }
//     setError(''); // Clear error if fields are valid
//     return true;
//   };

//   const nextStep = () => {
//     if (step === 1 && !validateStep1()) return;
//     if (step === 2 && !validateStep2()) return;
//     if (step === 3 && !validateStep3()) return;
//     setStep((prevStep) => prevStep + 1);
//   };

//   const prevStep = () => {
//     setStep((prevStep) => prevStep - 1);
//   };


//   return (
//     <div className="app">
//       <div className="popup-background">
//         <div className="popup">
//           <div style={{ display: 'flex', justifyContent: 'space-between' }}>
//             <h2>Shop {step === 1 ? "Details" : step === 2 ? "timing" : "Location"}</h2>
//             <ImCross style={{ color: '#8891F1', cursor: 'pointer' }} onClick={Cross} className="close-icon" />
//           </div>
//           {step === 1 && (
//             <>
//               <label className='label-popup-seller'>Shop Name <sup>*</sup></label>
//               <input
//                 type="text"
//                 name="shopName"
//                 placeholder="Shop name*"
//                 value={formData.shopName}
//                 onChange={handleChange}
//               />
//               <label className='label-popup-seller'>Shop Details <sup>*</sup></label>
//               <input
//                 type="text"
//                 name="shopDetails"
//                 placeholder="Shop details"
//                 value={formData.shopDetails}
//                 onChange={handleChange}
//               />
//               <div className='addtion-spantag'>
//               <label className='label-popup-seller '>Cost <sup>*</sup></label>
//               <input
//                 type="number"
//                 name="cost"
//                 placeholder="AED"
//                 value={formData.cost}
//                 onChange={handleChange}
//               />
//               <span className='span-tag-popup'>AED</span>
//               </div>
//               <div className='gapmargin' style={{ display: 'flex', position: 'relative', }}>
//               <input
//                   type="file"
//                   id="fileUpload"
//                   accept=".jpeg,.jpg,.png"
//                   className='inputfeild-sellar'
//                   onChange={handleFileChange}
//                   style={{ display: 'none', }}
//                 />
//                 <label htmlFor="fileUpload" style={{ borderBottom: '1px solid #c3c3c3', cursor: 'pointer', width: '100%', color: 'black', paddingBottom: 10, fontSize: '14px', paddingBottom: '1em' }}>
//                   {fileName || "Upload Shop Image"}
//                 </label>

//                 <p style={{ position: 'absolute', right: '2em', color: '#c3c3c3', fontSize: '14px', fontFamily: '"Manrope", sans-serif' }}>(jpeg, jpg, png)</p>
//                 <MdOutlineAttachment style={{ position: 'absolute', right: '10px', color: '#c3c3c3', fontSize: '18px', transform: 'rotate(130deg)' }} />
//               </div>
//               <div className='row-popup'>
//                 <button className='btn-left' onClick={Cross}>Cancel</button>
//                 <button className='btn-right' onClick={nextStep}>Continue</button>
//               </div>
//             </>
//           )}
//           {step === 2 && (
//             <>
//               {['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'].map((day) => (
//                 <div key={day} className="timing-row">
//                   <div className='week-part'>
//                     <label>{day.charAt(0).toUpperCase() + day.slice(1)}</label>
                    
//                   </div>
//                   <div className="timing-row">
//                     <Space direction="vertical" size={0}>
//                     <p className='icon-patagraph'  style={{margin:0,padding:0,fontSize:10}}>Start Time</p>  
//                     <TimePicker
//         className="custom-time-picker"
//         value={formData.timing[day].from ? moment(formData.timing[day].from) : null}
//         onChange={(time) => handleTimeChange(day, 'from', time)}
//         use12Hours
//         format="h:mm a"
//         disabled={!formData.timing[day].open}
//       />
//                     </Space>
//                     <Space direction="vertical" size={0}>
//                     <p className='icon-patagraph' style={{margin:0,padding:0,fontSize:10}}>End Time</p>  

//                     <TimePicker
//         className="custom-time-picker"
//         value={formData.timing[day].to ? moment(formData.timing[day].to) : null}
//         onChange={(time) => handleTimeChange(day, 'to', time)}
//         use12Hours
//         format="h:mm a"
//         disabled={!formData.timing[day].open}
//       />
//                     </Space>
//                     <Switch
//              onChange={() => handleActiveChange(day)}

//              checked={formData.timing[day].open}
//                       onColor="#747EEF"
//                       offColor="#bcbcbc"
//                       handleDiameter={17}
//                       uncheckedIcon={false}
//                       checkedIcon={false}
//                       boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
//                       activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
//                       height={20}
//                       width={36}
//                     />
//                   </div>
//                 </div>
//               ))}
//               <div className='row-popup'>
//                 <button className='btn-left' onClick={prevStep}>Back</button>
//                 <button className='btn-right' onClick={nextStep}>Continue</button>
//               </div>
//             </>
//           )}
//           {step === 3 && (
//             <>
//              <label className='label-popup-seller'>City <sup>*</sup></label>
             
//         <LoadScript googleMapsApiKey="AIzaSyB_nNvYWSCB2haI7DCgR6chQmsg-T4oj8s" libraries={['places']}>
//         <Autocomplete
//           onLoad={(autocomplete) => console.log('Autocomplete Loaded:', autocomplete)}
//           onPlaceSelected={handlePlaceSelected}
//           options={{
//             types: ['address'],
//             componentRestrictions: { country: "AE" },
//           }}
//           placeholder="Search for an address"
//           style={{
//             width: '100%',
//             height: '40px',
//             padding: '10px',
//             boxSizing: 'border-box',
//             borderRadius: '4px',
//             border: '1px solid #ccc',
//             marginBottom: '20px',
//           }}
//         />
//       </LoadScript>
//         <label className='label-popup-seller'>Your Estimated Service Timing <sup>*</sup></label>
//         <input
//           type="number"
//           name="estimatedServiceTime"
//           placeholder="Enter Your estimatedServiceTime"
//           value={formData.estimatedServiceTime}
//           onChange={handleChange}
//         />
//          <label className='label-popup-seller'>Radius (in km) <sup>*</sup></label>
//         <input
//           type="number"
//           name="radius"
//           placeholder="Enter radius"
//           value={formData.radius}
//           onChange={handleChange}
//         />
       
//        <LoadScript
//   googleMapsApiKey="AIzaSyB_nNvYWSCB2haI7DCgR6chQmsg-T4oj8s"
//   libraries={['places']}
//   async
//   defer
// >
//           <GoogleMap
//             mapContainerStyle={{ height: '300px', width: '100%' }}
//             center={formData.markerPosition}
//             zoom={12}
//             onClick={handleMapClick}
//           >
//             <Marker position={formData.markerPosition} />
//             {formData.radius && (
//               <Circle
//                 center={formData.markerPosition}
//                 radius={parseFloat(formData.radius) * 1000}
//                 options={{
//                   fillColor: 'rgba(136, 145, 241, 0.3)', // Light fill color
//                   strokeColor: '#8891F1', // Purple stroke color
//                   strokeOpacity: 0.8,
//                   strokeWeight: 2,
//                 }}
//               />
//             )}
//           </GoogleMap>
//         </LoadScript>
//         <div className='row-popup'>
//           <button className='btn-left' onClick={prevStep}>Back</button>
//           <button className='btn-right' onClick={handleSubmit} disabled={uploading}>
//     {uploading ? 'Uploading...' : editData ? 'Update' : 'Submit'}
// </button>
//         </div>
//             </>
//           )}
        
//         </div>
//       </div>
//     </div>
//   );
// };

// export default PopupSellar;
import React, { useEffect, useState } from 'react';
import { ImCross } from "react-icons/im";
import {toast } from 'react-toastify';
import { BsCloudUpload } from "react-icons/bs";
import 'react-toastify/dist/ReactToastify.css';
import { TimePicker, Space } from 'antd';
import 'antd/dist/reset.css';
import moment from 'moment';
import Switch from 'react-switch';
import { GoogleMap, LoadScript, Marker, Circle } from '@react-google-maps/api';
import axios from 'axios';
import './popup.css';
import Autocomplete from 'react-google-autocomplete';
const PopupSellar = ({ Cross, id ,fetchData,editData}) => {
  console.log('editData',editData)
  const [step, setStep] = useState(1);
  const [formData, setFormData] = useState({
    Owner: id,
    shopName: '',
    shopDetails: '',
    cost: '',
    uploadCover: null,
    city: '',
    isActive: true,
    radius: '',
    location: {
      country_city: "UAE , Dubai",
      text: "Istiqlal Street - near Immigration Office - Bu Danig - Sharjah - United Arab Emirates",
      lat: 67.0999638,
      long: 24.9214989,
    },
    estimatedServiceTime: "",
    timing: {
      monday: { open: false, from: '', to: '' },
      tuesday: { open: false, from: '', to: '' },
      wednesday: { open: false, from: '', to: '' },
      thursday: { open: false, from: '', to: '' },
      friday: { open: false, from: '', to: '' },
      saturday: { open: false, from: '', to: '' },
      sunday: { open: false, from: '', to: '' },
    },
  });
  console.log('fasdasd',formData)
  const formatTime = (dateTime) => {
    if (!dateTime) return '';
    const date = new Date(dateTime);
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    return `${hours}:${minutes}`;
  };
  
  const [error, setError] = useState('');

  const [uploading, setUploading] = useState(false);
  const [fileName, setFileName] = useState('');

 const token = localStorage.getItem('accessToken')
const handleTimeChange = (day, type, time) => {
  // Convert to local time using moment.js or Date to keep it in local time
  const localTime = time ? new Date(time).toLocaleString('en-US', { hour12: false }) : '';

  setFormData((prev) => ({
    ...prev,
    timing: {
      ...prev.timing,
      [day]: {
        ...prev.timing[day],
        [type]: localTime, // Save local time format
        open: true, // Set open to true when times are selected
      },
    },
  }));
};

  const handleFileChange = async (e) => {
    const file = e.target.files[0];
    setFileName(file ? file.name : '');
  
    if (!file) {
      toast.error("Please select a valid file");
      return;
    }
  
    try {
      const uploadFormData = new FormData();
      uploadFormData.append('file', file);
  
      // Set uploading state (optional, for a loader)
      setUploading(true);
  
      // Upload the file
      const uploadResponse = await axios.post('https://backend.washta.com/api/uploadFile', uploadFormData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${token}`, // Ensure your token is correct
        },
      });
  
      // Save the uploaded image URL in formData
      const uploadedImageUrl = uploadResponse?.data?.data?.path;
  
      if (uploadedImageUrl) {
        setFormData((prevFormData) => ({
          ...prevFormData,
          uploadCover: uploadedImageUrl, // Save the image URL
        }));
        toast.success("Image uploaded successfully!");
      } else {
        throw new Error("Failed to upload image. Please try again.");
      }
    } catch (error) {
      console.error("Error uploading image:", error);
      toast.error("Error uploading image. Please try again.");
    } finally {
      setUploading(false); // Reset uploading state
    }
  };
  
  // useEffect(() => {
  //   if (editData) {
     
  //     setFileName(editData?.coverImage)
  //     setFormData({
  //       ...formData,
  //       Owner: editData.Owner,
  //       shopName: editData.shopName,
  //       shopDetails: editData.shopDetails,
  //       cost: editData.cost,
  //       uploadCover: editData.coverImage, // Handle if coverImage should be uploaded
  //       city: '', // Update if you have a field for city
  //       isActive: editData.isActive,
  //       radius: editData.radius,
  //       location: {
  //         country_city: "UAE , Dubai", // Can be updated if city info is available
  //         text: editData.location.text,
  //         lat: editData.location.coordinates[1],
  //         long: editData.location.coordinates[0],
  //       },
  //       estimatedServiceTime: editData.estimatedServiceTime,
  //       timing: editData?.timing, // If timing data is present in editData
  //     });
  //   }
  // }, [editData]);
  useEffect(() => {
    if (editData) {
      const updatedTiming = {};
  
      // Loop through the timing object and handle each day
      Object.keys(editData.timing).forEach((day) => {
        const timeFrom = editData.timing[day].from;
        const timeTo = editData.timing[day].to;
  
        // Parse the 'from' and 'to' times, ensuring they're correctly handled
        const formattedFrom = moment(timeFrom, ['MM/DD/YYYY, HH:mm:ss', 'HH:mm']).isValid()
          ? moment(timeFrom, ['MM/DD/YYYY, HH:mm:ss', 'HH:mm']).format('MM/DD/YYYY, HH:mm:ss')
          : timeFrom;
  
        const formattedTo = moment(timeTo, ['MM/DD/YYYY, HH:mm:ss', 'HH:mm']).isValid()
          ? moment(timeTo, ['MM/DD/YYYY, HH:mm:ss', 'HH:mm']).format('MM/DD/YYYY, HH:mm:ss')
          : timeTo;
  
        // Add the formatted times to the updated timing object
        updatedTiming[day] = {
          ...editData.timing[day],
          from: formattedFrom,
          to: formattedTo,
        };
      });
  
      setFormData({
        ...formData,
        Owner: editData.Owner,
        shopName: editData.shopName,
        shopDetails: editData.shopDetails,
        cost: editData.cost,
        uploadCover: editData.coverImage, // Handle if coverImage should be uploaded
        city: '', // Update if you have a field for city
        isActive: editData.isActive,
        radius: editData.radius,
        location: {
          country_city: "UAE , Dubai", // Can be updated if city info is available
          text: editData.location.text,
          lat: editData.location.coordinates[1],
          long: editData.location.coordinates[0],
        },
        estimatedServiceTime: editData.estimatedServiceTime,
        timing: updatedTiming, // Set the updated timing here
      });
    }
  }, [editData]);

const handleActiveChange = (day) => {
    setFormData((prev) => ({
      ...prev,
      timing: {
        ...prev.timing,
        [day]: {
          ...prev.timing[day],
          open: !prev.timing[day].open,
          from: '',
          to: '',
        },
      },
    }));
  };


const handlePlaceSelected = (place) => {
    if (place && place.geometry) {
      const addressComponents = place.address_components;
      
      // Extract city and country from the address components
      const city = addressComponents.find(component => 
        component.types.includes("locality") || component.types.includes("administrative_area_level_1")
      )?.long_name || '';
      
      const country = addressComponents.find(component => 
        component.types.includes("country")
      )?.long_name || '';
  
      setFormData((prevData) => ({
        ...prevData,
        city: city, 
        location: {
          country_city: `${country}, ${city}`, // Fixing template literal syntax
          text: place.formatted_address, 
          lat: place.geometry.location.lat(), 
          long: place.geometry.location.lng(),
        },
        markerPosition: {
          lat: place.geometry.location.lat(),
          lng: place.geometry.location.lng(),
        },
        radius: formData.radius || '5', // Default radius if not specified
      }));
    }
  };
  

  const handleMapClick = (e) => {
    const lat = e.latLng.lat();
    const lng = e.latLng.lng();
    setFormData({
      ...formData,
      markerPosition: { lat, lng },
    });
  };

  const geocodeAddress = (address) => {
    const geocoder = new window.google.maps.Geocoder();
    console.log("Geocoding address:", address);  // Log the address
    geocoder.geocode({ address: address }, (results, status) => {
      console.log("Geocode results:", results);  // Log the results
      console.log("Geocode status:", status);    // Log the status
      if (status === 'OK') {
        const position = results[0].geometry.location;
        setFormData({
          ...formData,
          markerPosition: { lat: position.lat(), lng: position.lng() },
        });
      } else {
        console.log('Geocode was not successful for the following reason: ' + status);
      }
    });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };
  const triggerFileInput = () => {
    document.getElementById("file-input").click();
  };
const handleSubmit = async (e) => {
  e.preventDefault();

  // Validate required fields
  if (!validateStep1()) return;



  setUploading(true);


  try {

        const payload = {
      shopName: formData.shopName,
      coverImage: formData.uploadCover || editData?.coverImage, // Use the new or existing image URL
      isActive: true,
      shopDetails: formData.shopDetails,
      location: {
        text: formData.location.text,
        _id: formData.location._id, 
        lat: 67.0999638,
        long: 24.9214989,
            coordinates: [
         formData.location.lat,
        formData.location.long
            ]
      },
      timing: Object.keys(formData.timing).reduce((acc, day) => {
            acc[day] = {
              open: formData.timing[day].open,
              from: formatTime(formData.timing[day].from),  // Format time
              to: formatTime(formData.timing[day].to),      // Format time
            };
            return acc;
          }, {}),
      cost: formData.cost,
      radius: formData.radius,
      estimatedServiceTime: formData.estimatedServiceTime
    };

    // Determine endpoint and method
    const apiEndpoint = editData ? `/api/seller/shop/${editData._id}` : '/api/seller/shop';
    const httpMethod = editData ? 'PATCH' : 'POST';

    // Make the API call
    const response = await axios({
      method: httpMethod,
      url: `https://backend.washta.com${apiEndpoint}`,
      data: payload,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    });

    if (response.status === 200) {
      toast.success(editData ? 'Shop details updated successfully!' : 'Shop details submitted successfully!');
      fetchData(); // Refresh data
      Cross(); // Close popup
    } else {
      toast.error('Failed to submit shop details');
    }
  } catch (error) {
    if (error.response?.status === 429) {
      toast.error('Too many requests, please try again later.');
    } else {
      console.error('Error:', error);
      toast.error('Error submitting shop details');
    }
  } finally {
    setUploading(false);
  }
};




// Validate Step 1
const validateStep1 = () => {
  if (!formData.shopName || !formData.shopDetails || !formData.cost || !formData.uploadCover) {
    toast.error('Please fill all fields and upload an image');
    return false;
  }
  setError('');  // Clear error message
  return true;
};

  const validateStep2 = () => {
    let isAnyActive = false; 
    let hasError = false; 
    let errorMessages = []; // To accumulate error messages
    
    for (const day in formData.timing) {
      const { from, to, open } = formData.timing[day];
      
      if (open) { // Check if the day is active
        isAnyActive = true;
        if (!from || !to) {
          const errorMessage = `Please fill in start and end times for ${day.charAt(0).toUpperCase() + day.slice(1)}.`;
          errorMessages.push(errorMessage); // Accumulate error messages
          setError(errorMessage); // Set the error state for the latest error
          toast.error(errorMessage); // Show toast notification for the latest error
          hasError = true; // Set the error flag
        }
      }
    }
  
    // Check if at least one day is active
    if (!isAnyActive) {
      const errorMessage = 'Please activate at least one day.';
      errorMessages.push(errorMessage); // Accumulate error messages
      setError(errorMessage); // Set the error state
      toast.error(errorMessage); // Show toast notification
      return false; // Prevent navigation
    }
  
    // If there is an error, prevent navigation
    if (hasError) {
      return false; // Prevent navigation if there are errors
    }
  
    // If you want to show all accumulated error messages:
    if (errorMessages.length > 0) {
      toast.error(errorMessages.join('\n')); // Show all accumulated error messages at once
    }
  
    setError(''); // Clear error if all active days are valid
    return true; // Pass validation
  };
  
  
  
  const validateStep3 = () => {
    if (!formData.selectedCity || !formData.radius) {
      toast.error('Please fill all fields');
      return false;
    }
    setError(''); // Clear error if fields are valid
    return true;
  };

  const nextStep = () => {
    if (step === 1 && !validateStep1()) return;
    if (step === 2 && !validateStep2()) return;
    if (step === 3 && !validateStep3()) return;
    setStep((prevStep) => prevStep + 1);
  };

  const prevStep = () => {
    setStep((prevStep) => prevStep - 1);
  };


  return (
    <div className="app">
      <div className="popup-background">
        <div className="popup">
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <h2>Shop {step === 1 ? "Details" : step === 2 ? "timing" : "Location"}</h2>
            <ImCross style={{ color: '#8891F1', cursor: 'pointer' }} onClick={Cross} className="close-icon" />
          </div>
          {step === 1 && (
            <>
              <label className='label-popup-seller'>Shop Name <sup>*</sup></label>
              <input
                type="text"
                name="shopName"
                placeholder="Shop name*"
                value={formData.shopName}
                onChange={handleChange}
              />
              <label className='label-popup-seller'>Shop Details <sup>*</sup></label>
              <input
                type="text"
                name="shopDetails"
                placeholder="Shop details"
                value={formData.shopDetails}
                onChange={handleChange}
              />
              <div className='addtion-spantag'>
              <label className='label-popup-seller '>Cost <sup>*</sup></label>
              <input
                type="number"
                name="cost"
                placeholder="AED"
                value={formData.cost}
                onChange={handleChange}
              />
              <span className='span-tag-popup'>AED</span>
              </div>
              {/* <div className='gapmargin' style={{ display: 'flex', position: 'relative', }}>
              <input
                  type="file"
                  id="fileUpload"
                  accept=".jpeg,.jpg,.png"
                  className='inputfeild-sellar'
                  onChange={handleFileChange}
                  style={{ display: 'none', }}
                />
                <label htmlFor="fileUpload" style={{ borderBottom: '1px solid #c3c3c3', cursor: 'pointer', width: '100%', color: 'black', paddingBottom: 10, fontSize: '14px', paddingBottom: '1em' }}>
                  {fileName || "Upload Shop Image"}
                </label>

                <p style={{ position: 'absolute', right: '2em', color: '#c3c3c3', fontSize: '14px', fontFamily: '"Manrope", sans-serif' }}>(jpeg, jpg, png)</p>
                <MdOutlineAttachment style={{ position: 'absolute', right: '10px', color: '#c3c3c3', fontSize: '18px', transform: 'rotate(130deg)' }} />
              </div> */}
              {/* <div>
              <button onClick={handleFileChange} className='btn-right flex items-center justify-center rounded-[40px] px-[10px] py-[14px]'  > <BsCloudUpload className="mr-2 text-2xl" />Upload Image</button>

              </div> */}
              <div>
      <input
        type="file"
        id="file-input"
        style={{ display: "none" }}
        onChange={handleFileChange}
      />
      <button
        onClick={triggerFileInput}
        className="btn-right flex items-center justify-center rounded-[40px] px-[10px] py-[14px]"
      >
        <BsCloudUpload className="mr-2 text-2xl" />
        Upload Image
      </button>
    </div>
              <div className='row-popup'>
                <button className='btn-left' onClick={Cross}>Cancel</button>
                <button className='btn-right' onClick={nextStep}>Continue</button>
              </div>
            </>
          )}
          {step === 2 && (
            <>
              {['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'].map((day) => (
                <div key={day} className="timing-row">
                  <div className='week-part'>
                    <label>{day.charAt(0).toUpperCase() + day.slice(1)}</label>
                    
                  </div>
                  <div className="timing-row">
                    <Space direction="vertical" size={0}>
                    <p className='icon-patagraph'  style={{margin:0,padding:0,fontSize:10}}>Start Time</p>  
                    <TimePicker
        className="custom-time-picker"
        value={formData.timing[day].from ? moment(formData.timing[day].from) : null}
        onChange={(time) => handleTimeChange(day, 'from', time)}
        use12Hours
        format="h:mm a"
        disabled={!formData.timing[day].open}
      />

                    </Space>
                    <Space direction="vertical" size={0}>
                    <p className='icon-patagraph' style={{margin:0,padding:0,fontSize:10}}>End Time</p>  

                    <TimePicker
        className="custom-time-picker"
        value={formData.timing[day].to ? moment(formData.timing[day].to) : null}
        onChange={(time) => handleTimeChange(day, 'to', time)}
        use12Hours
        format="h:mm a"
        disabled={!formData.timing[day].open}
      />
                    </Space>
                    <Switch
             onChange={() => handleActiveChange(day)}

             checked={formData.timing[day].open}
                      onColor="#747EEF"
                      offColor="#bcbcbc"
                      handleDiameter={17}
                      uncheckedIcon={false}
                      checkedIcon={false}
                      boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                      activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                      height={20}
                      width={36}
                    />
                  </div>
                </div>
              ))}
              <div className='row-popup'>
                <button className='btn-left' onClick={prevStep}>Back</button>
                <button className='btn-right' onClick={nextStep}>Continue</button>
              </div>
            </>
          )}
          {step === 3 && (
            <>
                 <div className='addtion-spantag'>
               <label className='label-popup-seller'>Estimated Service Timing <sup>*</sup></label>
               <input
                 type="number"
                name="estimatedServiceTime"
                placeholder="Estimated Service Timing*"
                value={formData.estimatedServiceTime}
                onChange={handleChange}
              />
                <span className='span-tag-popup'>Minutes</span>
              </div>
             <label className='label-popup-seller'>City <sup>*</sup></label>
             
        <LoadScript googleMapsApiKey="AIzaSyB_nNvYWSCB2haI7DCgR6chQmsg-T4oj8s" libraries={['places']}>
        <Autocomplete
          onLoad={(autocomplete) => console.log('Autocomplete Loaded:', autocomplete)}
          onPlaceSelected={handlePlaceSelected}
          options={{
            types: ['address'],
            componentRestrictions: { country: "AE" },
          }}
          placeholder="Search for an address"
          style={{
            width: '100%',
            height: '40px',
            padding: '10px',
            boxSizing: 'border-box',
            borderRadius: '4px',
            border: '1px solid #ccc',
            marginBottom: '20px',
          }}
        />
      </LoadScript>
        <label className='label-popup-seller'>Radius (in km) <sup>*</sup></label>
        <input
          type="number"
          name="radius"
          placeholder="Enter radius"
          value={formData.radius}
          onChange={handleChange}
        />
       
       <LoadScript
  googleMapsApiKey="AIzaSyB_nNvYWSCB2haI7DCgR6chQmsg-T4oj8s"
  libraries={['places']}
  async
  defer
>
          <GoogleMap
            mapContainerStyle={{ height: '300px', width: '100%' }}
            center={formData.markerPosition}
            zoom={12}
            onClick={handleMapClick}
          >
            <Marker position={formData.markerPosition} />
            {formData.radius && (
              <Circle
                center={formData.markerPosition}
                radius={parseFloat(formData.radius) * 1000}
                options={{
                  fillColor: 'rgba(136, 145, 241, 0.3)', // Light fill color
                  strokeColor: '#8891F1', // Purple stroke color
                  strokeOpacity: 0.8,
                  strokeWeight: 2,
                }}
              />
            )}
          </GoogleMap>
        </LoadScript>
        <div className='row-popup'>
          <button className='btn-left' onClick={prevStep}>Back</button>
          <button className='btn-right' onClick={handleSubmit} disabled={uploading}>
    {uploading ? 'Uploading...' : editData ? 'Update' : 'Submit'}
</button>
        </div>
            </>
          )}
        
        </div>
      </div>
    </div>
  );
};

export default PopupSellar;