import AdminSidebar from 'admin/sidebar/AdminSidebar';
import React, { useEffect, useState } from 'react'
import { CiSearch } from "react-icons/ci";
import LOGO from '../../../assets/images/app.logo.jpeg'
import axios from 'axios';
import { Skeleton } from '@mui/material';
import DELETEICOn from '../../../assets/images/deleteIcon.png'
import NoDataImage from '../../../assets/images/nodataImg.png'; // Add the path to your image here
import { formatDate, formatTimeInTimezone } from 'pages/utils/formdatedynamics';

const AllCompaniesAppRe = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState('');
  useEffect(() => {
    window.scrollTo(0, 0);
    const fetchData = async () => {
      const token = localStorage.getItem('accessTokenAdmin'); // Retrieve token from local storage
      const apiUrl = 'https://backend.washta.com/api/admin/shop'; // Replace with your API endpoint

      try {
        const response = await axios.get(apiUrl, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setData(response.data.data.reverse());
        console.log(response.data.data)
        setLoading(false); // Set loading to false after data is fetched
      } catch (err) {
        console.log(err.message);
        // Set loading to false in case of an error
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const filteredData = data.filter(item => {
    const shopName = item.shopName ? item.shopName.toLowerCase() : '';
    const cost = typeof item.cost === 'string' ? item.cost.toLowerCase() : ''; // Ensure cost is a string
    const search = searchTerm.toLowerCase();
  
    return shopName.includes(search) || cost.includes(search);
  });
  
  return (
    <div className='mainDiv-all-sideBar'>
    <div className='div-sidebar'>
<AdminSidebar/>
    </div>
    <div className='div-Dashbaord'>
    <div className='div-row-all-customer'>
          <h1 className='h1-all-heading'>All Shops</h1>
          <div className='input-feild-dic-icon all-cutomerssearch'>
            <CiSearch className='icon-serach-box-input' />
            <input
              placeholder='Search by username or email'
              className='input-feild-class-job-history'
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
          </div>
        </div>
    <div className="dashboard">    
    <div className='styles-div' style={{ width: '100%'}}>
            {loading ? (
              Array.from(new Array(10)).map((_, index) => (
                <div key={index} style={{ background: '#747eef9c', padding: '10px 20px', display: 'flex', alignItems: 'center', borderRadius: '8px', border: '1px solid #ddd', marginBottom: '10px', marginTop: 20 }}>
                  <Skeleton variant="rectangular" width={48} height={53} style={{ marginRight: '10px', borderRadius: '8px', background: '#747eef9c' }} />
                  <div style={{ flexGrow: 1 }}>
                    <Skeleton variant="text" width="50%" height={20} style={{ marginBottom: '0px', background: '#f1f2fd' }} />
                    <Skeleton style={{ background: '#f1f2fd' }} variant="text" width="50%" height={20} />
                  </div>
                  <div style={{ flexGrow: 1 }}>
                    <Skeleton variant="text" width="50%" height={20} style={{ marginBottom: '0px', background: '#f1f2fd' }} />
                    <Skeleton style={{ background: '#f1f2fd' }} variant="text" width="50%" height={20} />
                  </div>
                  <div style={{ flexGrow: 1 }}>
                    <Skeleton variant="text" width="50%" height={20} style={{ marginBottom: '0px', background: '#f1f2fd' }} />
                    <Skeleton style={{ background: '#f1f2fd' }} variant="text" width="50%" height={20} />
                  </div>
                  <div style={{ flexGrow: 1 }}>
                    <Skeleton variant="text" width="50%" height={20} style={{ marginBottom: '0px', background: '#f1f2fd' }} />
                    <Skeleton style={{ background: '#f1f2fd' }} variant="text" width="50%" height={20} />
                  </div>
                </div>
              ))
              ) : data?.length === 0 ? (
                <div style={{display:'flex',justifyContent:'center'}} >
                  <img src={NoDataImage} alt="No Data" style={{ maxWidth: '100%', maxHeight: '100%' }} />
                </div>
              ) : (
              filteredData.map((item) => (
                <div className='main-invoice-div' style={{ marginTop: 15 }} key={item.id}>
                  <div className='row-invoice' style={{width:'100%'}}>
                    <div className='row-invoice' style={{ justifyContent: 'flex-start',width:'100%'}}>
                      <img src={item.coverImage && item.coverImage.includes('/media/image/cover-image.jpeg')
          ? LOGO
          : item.coverImage || LOGO} style={{ width: '5%', boxShadow: '0px 0px 3px 0px #333', borderRadius: '15%',objectFit:'cover',height:'7vh' }} />
                      <div className='div-text-image-immer-text' style={{ width: '25%' }}>
                        <h5 className='h5-heaidng-invouice'>{item.shopName}</h5>
                        <p className='paragph-heading'>{item.shopDetails}</p>
                      </div>
                      <div className='div-text-image-immer-text' style={{ width: '25%' }}>
                        <h5 className='h5-heaidng-invouice'>Price</h5>
                        <p className='paragph-heading'>{item.cost}</p>
                      </div>
                      <div className='div-text-image-immer-text' style={{ width: '25%' }}>
                        <h5 className='h5-heaidng-invouice'>Location</h5>
                        <p className='paragph-heading'>ABD-shopping-mall </p>
                      </div>
                      <div className='div-text-image-immer-text' style={{ width: '25%' }}>
                        <h5 className='h5-heaidng-invouice'>Date Of Joining</h5>
                        <p className='paragph-heading'>{formatDate(item?.updatedAt) + " At " + formatTimeInTimezone(item?.updatedAt)}</p>
                      </div>
                      <div><img style={{ width: 20 }} src={DELETEICOn} alt="Delete Icon" /></div>
                    </div>
                  </div>
                </div>
              ))
            )}
          </div>
        </div>
    </div>
    </div>
  )
}

export default AllCompaniesAppRe