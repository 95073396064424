import React from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, IconButton, Typography } from '@mui/material';
import ButtonDahbaord from 'components/ButtonDahbaord/ButtonDahbaord';
import { IoCloseOutline } from "react-icons/io5";
import { formatDate, formatTimeInTimezone } from 'pages/utils/formdatedynamics';
const calculateFinalCost = (popupData) => {
  let cost = parseFloat(popupData?.cost || 0);
  let vatFee = cost * 0.05; 
  let serviceFee = 0;
  let discount = 0;
  let promoCodeText = "No Promo Code Applied";
  if (popupData?.serviceFee?.length) {
    const feeData = popupData.serviceFee[0];
    if (feeData.feeType === 'percentage') {
      serviceFee = (parseFloat(feeData.WashtaFees) / 100) * cost;
    } else {
      serviceFee = parseFloat(feeData.WashtaFees);
    }
  }

  // Process promo code discount
  if (popupData?.promoCode) {
    if (popupData.promoCode.Discounttype === 'percentage') {
      discount = (parseFloat(popupData.promoCode.discount) / 100) * cost;
      promoCodeText = `${popupData.promoCode.promoCode} (${popupData.promoCode.discount}% off)`;
    } else {
      discount = parseFloat(popupData.promoCode.discount);
      promoCodeText = `${popupData.promoCode.promoCode} (Fixed Discount: ${discount} AED)`;
    }
  }

  let totalReduction = serviceFee + vatFee + discount;
  let finalCost = cost - totalReduction;

  return { 
    finalCost: finalCost.toFixed(2), 
    totalReduction: totalReduction.toFixed(2),
    vatFee: vatFee.toFixed(2),
    serviceFee: serviceFee.toFixed(2),
    discount: discount.toFixed(2),
    promoCodeText
  };
};
const PopupCustomer = ({  show, onHide,  popupData, onViewInvoice,invoiceShow,onClose,onDeleteInoive }) => {
  const { finalCost, totalReduction, vatFee, serviceFee, discount, promoCodeText } = calculateFinalCost(popupData[0]);
  console.log("asdasdasd",popupData[0])
  return (
    <Dialog
      open={show}
      onClose={onHide}
      fullWidth
      maxWidth="lg"
    >
      <DialogTitle>
        {popupData?.customerId?.username}
        <IconButton
          edge="end"
          color="inherit"
          onClick={onHide}
          aria-label="close"
          sx={{ position: 'absolute', right: 8, top: 8, color: (theme) => theme.palette.grey[500] }}
        >
          <IoCloseOutline />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <div className='popup-pending-appliction'>
          <div className='div-left-popup' style={{ width: '50%' }}>
            <Typography variant="h6">Car Details</Typography>
            {[
              { label: 'Buyer Name', value: popupData[0]?.customerId?.username || 'Buyer Name:' },
              { label: 'Car', value: popupData[0]?.vehicleId?.vehicleName || '' },
              { label: 'Car Type', value: popupData[0]?.vehicleId?.vehicleType || '' },
              { label: 'Car Plate Number', value: popupData[0]?.vehicleId?.vehiclePlateNumber || '' },
              { label: 'Promo Code', value: promoCodeText },
              { label: 'Shop Total Price', value: popupData[0]?.cost + ".00 AED" || '' },
              { label: `Washta Fee (${popupData[0]?.serviceFee[0]?.WashtaFees} ${popupData[0]?.serviceFee[0]?.feeType === 'percentage' ? '%' : ' AED'})`, value: serviceFee + " AED" || '' },


            ].map((item, index) => (
              <div key={index} className='popup-inputfeild-application'>
                <p className='vat-number-api'>{item.label}:</p>
                <input className='valt-number-input-feild' placeholder='text' value={item.value} readOnly />
              </div>
            ))}
          </div>
          <div className='div-left-popup' style={{ width: '50%' }}>
            <Typography variant="h6">Order Details</Typography>
            {[
              { label: 'Order ID', value: popupData[0]?._id.slice(0,15) || '' },
              { label: 'Order Date',value: formatDate(popupData[0]?.createdAt) || '' },
              { label: 'Order Time', value: formatTimeInTimezone(popupData[0]?.createdAt) || '' },
              { label: 'Billing Status', value: popupData[0]?.billingStatus || '' },
              { label: 'Promo Code Discount', value: discount + " AED" || '' },
              { label: 'Vat 5%', value: vatFee + " AED" || '' },
              { label: 'Total Deduction', value: totalReduction + "AED" || '' },
            ].map((item, index) => (
              <div key={index} className='popup-inputfeild-application'>
                <p className='vat-number-api'>{item.label}:</p>
                <input className='valt-number-input-feild' placeholder='text' value={item.value} readOnly />
              </div>
            ))}
          </div>
        </div>
      </DialogContent>
      <DialogActions>
        <div className='paymanet-div-super-admin'>
          <Typography variant="body2">Payment</Typography>
          <Typography variant="body1">
            {finalCost} <span style={{ fontSize: 12 }}>AED</span>
          </Typography>
        </div>
        <div className='second-payment-div'>
          <ButtonDahbaord
            handleclick={onViewInvoice}
            textButton={'View Invoice'}
            height={32}
            width={"24%"}
            background='transparent'
          />
          <ButtonDahbaord
            handleclick={onDeleteInoive}
            textButton={'Delete'}
            height={32}
            width={"18%"}
              background='purple'
          />
          {/* <ButtonDahbaord
            handleclick={onViewInvoice}
            textButton={"Share details"}
            height={32}
            width={"24%"}
            background='purple'
          /> */}
        </div>
      </DialogActions>
    </Dialog>
  );
}

export default PopupCustomer;
