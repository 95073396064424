// import React, { useEffect } from 'react';
// import './Modal.css';
// import { RxCross1 } from "react-icons/rx";
// import { formatDate, formatTimeInTimezone } from 'pages/utils/formdatedynamics';

// function PopupInvoice({ isOpen, onClose, data }) {
//   console.log('data',data)
//     useEffect(() => {
//         // Handle clicks outside of the modal content
//         const handleClickOutside = (event) => {
//           if (event.target.classList.contains('modal-overlay')) {
//             onClose();
//           }
//         };
    
//         if (isOpen) {
//           document.addEventListener('click', handleClickOutside);
//         }
    
//         return () => {
//           document.removeEventListener('click', handleClickOutside);
//         };
//       }, [isOpen, onClose]);
//       const costPercentage = data?.cost ? (data?.cost * 0.05).toFixed(2) : 0;
//       const finalCost = data?.cost && data?.fee ? (data?.cost - costPercentage - data?.fee).toFixed(2) : 'N/A';

//       return (
//         <div className={`modal-overlay ${isOpen ? 'open' : 'close'}`}>
//           <div className={`modal-content ${isOpen ? 'slide-in' : 'slide-out'}`}>
//           <div className='row-modal-popup margin-bottom' >
//           <h2 className='h2-heading-main'>Invoice Details</h2>
//            <RxCross1 style={{cursor:'pointer'}} onClick={onClose}/>
//           </div>
         
           
//             <div className='popup-pending-appliction'>
           
//             <div className='popup-inputfeild-application addtionwidth' >
//               <p className='vat-number-api'>BillingStatus :</p>
//               <input className='valt-number-input-feild' placeholder='text' value={data?.billingStatus || ''} readOnly />
//             </div>
//             <div className='popup-inputfeild-application addtionwidth'>
//             <p className='vat-number-api' style={{width:"25%"}}>Status :</p>
//             <input className='valt-number-input-feild' style={{width:"75%"}} placeholder='text' value={ data?.status || ''} readOnly />
             
//             </div>
//             </div>
//             <div className='popup-pending-appliction'>
           
//             <div className='popup-inputfeild-application addtionwidth' >
//               <p className='vat-number-api' style={{width:"40%"}}>Customer Email :</p>
//               <input className='valt-number-input-feild' style={{width:"60%"}} placeholder='text' value={data?.customerId?.email || 'no email'} readOnly />
//             </div>
            
//             <div className='popup-inputfeild-application addtionwidth'>
//             <p className='vat-number-api'>Cost :</p>
//             <input className='valt-number-input-feild' placeholder='text' value={ data?.cost + ".00" +  " AED"  || ''} readOnly />
//             </div>
//             </div>

//             <div className='popup-pending-appliction'>
           
//             <div className='popup-inputfeild-application addtionwidth' >
//               <p className='vat-number-api' style={{width:"40%"}}>Customer Name :</p>
//               <input className='valt-number-input-feild' style={{width:"60%"}} placeholder='text' value={data?.customerId?.username || ''} readOnly />
//             </div>
            
//             <div className='popup-inputfeild-application addtionwidth'>
//             <p className='vat-number-api' style={{width:"25%"}}>Vat 5% :</p>
//             <input className='valt-number-input-feild' style={{width:"75%"}} placeholder='text' value={costPercentage + " AED"  || 'none'} readOnly />
//             </div>
//             </div>
            
//             <div className='popup-pending-appliction'>
           
//             <div className='popup-inputfeild-application addtionwidth' >
//             <p className='vat-number-api' style={{width:"25%"}}>Location :</p>
//             <input className='valt-number-input-feild' style={{width:"75%"}} placeholder='text' value={ data?.location?.text || 'no location'} readOnly />
             
//             </div>
//             <div className='popup-inputfeild-application addtionwidth'>
//             <p className='vat-number-api' style={{width:"40%"}}>Washta Fee 15% :</p>
//             <input className='valt-number-input-feild' style={{width:"60%"}} placeholder='text' value={ data?.fee + " AED"  || 'none'} readOnly />
//             </div>
//             </div>
//             <div className='popup-pending-appliction'>
           
//            <div className='popup-inputfeild-application addtionwidth' >
//            <p className='vat-number-api' style={{width:"25%"}}>Date :</p>
//             <input className='valt-number-input-feild' style={{width:"75%"}} placeholder='text' value={formatDate( data?.date )  +  " At "  +  formatTimeInTimezone( data?.date) || ''} readOnly />
//            </div>
//            <div className='popup-inputfeild-application addtionwidth'>
//              <p className='vat-number-api' style={{width:"40%"}}>Total :</p>
//              <input className='valt-number-input-feild' style={{width:"60%"}} placeholder='text' value={ finalCost + " AED"  || 'none'} readOnly />
//            </div>
//            </div>
//             {/* Add more details as needed */}
//           </div>
//         </div>
//       );
//     }

// export default PopupInvoice;
import React, { useEffect } from 'react';
import './Modal.css';
import { RxCross1 } from "react-icons/rx";
import { formatDate, formatTimeInTimezone } from 'pages/utils/formdatedynamics';

function PopupInvoice({ isOpen, onClose, data }) {
  console.log('data', data);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (event.target.classList.contains('modal-overlay')) {
        onClose();
      }
    };

    if (isOpen) {
      document.addEventListener('click', handleClickOutside);
    }

    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [isOpen, onClose]);

  // Extract necessary data
  const cost = parseFloat(data?.cost) || 0;
  const serviceFee = data?.serviceFee?.[0]; // Assuming first service fee object
  const promoCode = data?.promoCode;
  const discountType = promoCode?.Discounttype;
  const discountValue = parseFloat(promoCode?.discount) || 0;
  const vatPercentage = 5; // 5% VAT

  // Step 1: Calculate VAT (always applied)
  const vatAmount = (cost * vatPercentage) / 100;

  // Step 2: Deduct Service Fee (Washta Fee)
  let serviceFeeAmount = 0;
  if (serviceFee) {
    if (serviceFee.feeType === 'percentage') {
      serviceFeeAmount = (cost * parseFloat(serviceFee.WashtaFees)) / 100;
    } else if (serviceFee.feeType === 'fixed') {
      serviceFeeAmount = parseFloat(serviceFee.WashtaFees);
    }
  }

  // Step 3: Apply Promo Code Discount (only if applied)
  let discountAmount = 0;
  if (promoCode) {
    if (discountType === 'percentage') {
      discountAmount = ((cost) * discountValue) / 100;
    } else if (discountType === 'fixed') {
      discountAmount = discountValue;
    }
  }
console.log('discountAmount',discountAmount)
  // Step 4: Calculate Total Deductions
  const totalDeductions = vatAmount + serviceFeeAmount + discountAmount;

  // Step 5: Calculate Final Total Price
  const finalCost = (cost - totalDeductions).toFixed(2);

  return (
    <div className={`modal-overlay ${isOpen ? 'open' : 'close'}`}>
      <div className={`modal-content ${isOpen ? 'slide-in' : 'slide-out'}`}>
        <div className='row-modal-popup margin-bottom'>
          <h2 className='h2-heading-main'>Invoice Details</h2>
          <RxCross1 style={{ cursor: 'pointer' }} onClick={onClose} />
        </div>

        <div className='popup-pending-appliction'>
          <div className='popup-inputfeild-application addtionwidth'>
            <p className='vat-number-api'>Billing Status:</p>
            <input className='valt-number-input-feild' value={data?.billingStatus || ''} readOnly />
          </div>
          <div className='popup-inputfeild-application addtionwidth'>
            <p className='vat-number-api'>Status:</p>
            <input className='valt-number-input-feild' value={data?.status || ''} readOnly />
          </div>
        </div>

        <div className='popup-pending-appliction'>
          <div className='popup-inputfeild-application addtionwidth'>
            <p className='vat-number-api'>Customer Email:</p>
            <input className='valt-number-input-feild' value={data?.customerId?.email || 'no email'} readOnly />
          </div>
          <div className='popup-inputfeild-application addtionwidth'>
            <p className='vat-number-api'>Shop Price:</p>
            <input className='valt-number-input-feild' value={`${cost.toFixed(2)} AED`} readOnly />
          </div>
        </div>

        <div className='popup-pending-appliction'>
          <div className='popup-inputfeild-application addtionwidth'>
            <p className='vat-number-api'>Customer Name:</p>
            <input className='valt-number-input-feild' value={data?.customerId?.username || ''} readOnly />
          </div>
          <div className='popup-inputfeild-application addtionwidth'>
            <p className='vat-number-api'>VAT 5%:</p>
            <input className='valt-number-input-feild' value={`${vatAmount.toFixed(2)} AED`} readOnly />
          </div>
        </div>

        <div className='popup-pending-appliction'>
          <div className='popup-inputfeild-application addtionwidth'>
            <p className='vat-number-api'>Location:</p>
            <input className='valt-number-input-feild' value={data?.location?.text || 'no location'} readOnly />
          </div>
          <div className='popup-inputfeild-application addtionwidth'>
            <p className='vat-number-api'>Washta Fee {serviceFee?.WashtaFees}%:</p>
            <input className='valt-number-input-feild' value={`${serviceFeeAmount.toFixed(2)} AED`} readOnly />
          </div>
        </div>

        {promoCode && (
          <div className='popup-pending-appliction'>
            <div className='popup-inputfeild-application addtionwidth'>
              <p className='vat-number-api'>Promo Code:</p>
              <input className='valt-number-input-feild' value={promoCode?.promoCode} readOnly />
            </div>
            <div className='popup-inputfeild-application addtionwidth'>
              <p className='vat-number-api'>Promo Code Discount ({promoCode.discount}{promoCode?.Discounttype === "percentage" ? '%' : ' AED'}):</p>
              <input className='valt-number-input-feild' value={`${discountAmount.toFixed(2)} AED`} readOnly />
            </div>
          </div>
        )}

       

        <div className='popup-pending-appliction'>
          <div className='popup-inputfeild-application addtionwidth'>
            <p className='vat-number-api'>Date:</p>
            <input className='valt-number-input-feild' value={`${formatDate(data?.date)} At ${formatTimeInTimezone(data?.date)}`} readOnly />
          </div>
          <div className='popup-inputfeild-application addtionwidth'>
            <p className='vat-number-api'>Total Deductions:</p>
            <input className='valt-number-input-feild' value={`${totalDeductions.toFixed(2)} AED`} readOnly />
          </div>
        </div>
        <div className='popup-pending-appliction'>
          <div className='popup-inputfeild-application addtionwidth'>
            <p className='vat-number-api'>Total:</p>
            <input className='valt-number-input-feild' value={`${finalCost} AED`}  readOnly />
          </div>
        </div>
      </div>
    </div>
  );
}

export default PopupInvoice;
